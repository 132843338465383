import axios from 'axios'

import { API_BASE_URL } from '../config/env'

import { getIdToken } from '../utils'

export const FRONT = '?output=front'
export const PAGES = [
  'dados',
  'ativo',
  'passivo',
  'mutacao',
  'dre',
  'fundos',
  'parceiros',
  'highlights'
]

// EXPORT ALL DATA FROM API
export const loadAllDataFromAPI = async (document, isGroup, dateVersion) => {
  const token = await getIdToken()

  const params = dateVersion
    ? {
      id: document,
      output: 'front',
      is_group: !!isGroup,
      date_version: dateVersion
    }
    : {
      id: document,
      output: 'front',
      is_group: !!isGroup
    }

  return await axios
    .get(`${API_BASE_URL}/spread/get`, {
      params,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(({ data }) => {
      if (data !== '') {
        return data.data
      }
      return false
    })
    .catch(error => {
      const errorMessage = error.response?.data?.message || error.message
      const details = error.response?.data?.details || ''
      console.log(
        '%c API ERROR -> ',
        'background:red; color:white',
        errorMessage,
        details
      )

      if (errorMessage.includes('NoSuchKey') || details.includes('NoSuchKey')) {
        throw new Error('Document not found')
      } else if (errorMessage.includes('Grupo do usuário não autorizado.')) {
        throw new Error('User group not authorized')
      } else {
        throw new Error(error)
      }
    })
}

// RESET SPREAD
export const ResetSpread = async ({ dados, isGroup }) => {
  dados = {
    ...dados,
    datas: []
  }

  const token = await getIdToken()

  console.log('dados reset', dados)

  return new Promise((resolve, reject) =>
    axios
      .post(
        `${API_BASE_URL}/reset`,
        { ...dados, is_group: !!isGroup },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(result => resolve(result))
      .catch(error => reject(error))
  )
}

export const changeDate = async ({
  id,
  dataAntiga,
  dataNova,
  authKey,
  isGroup
}) => {
  const token = await getIdToken()

  const { data } = await axios.post(
    `${API_BASE_URL}/alterar_coluna`,
    {
      id,
      data_antiga: dataAntiga,
      data_nova: dataNova,
      is_group: !!isGroup
    },
    {
      headers: {
        'x-auth-key': authKey,
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

export const deleteDate = async ({ id, data, authKey, isGroup }) => {
  const token = await getIdToken()
  const { data: responseData } = await axios.post(
    `${API_BASE_URL}/remove_coluna`,
    {
      id,
      data,
      is_group: !!isGroup
    },
    {
      headers: {
        'x-auth-key': authKey,
        Authorization: `Bearer ${token}`
      }
    }
  )

  return responseData
}

export const save = async (document, props, isGroup) => {
  const id = document.replace(/\D/g, '')
  isGroup = !!isGroup
  const reduxData = {
    dados: {
      ...props.dados,
      id,
      is_group: isGroup
    },
    ativo: {
      ...props.ativo,
      id,
      is_group: isGroup,
      items: {
        ...props.ativo.items,
        total: props.ativo.total
      }
    },
    passivo: {
      ...props.passivo,
      id,
      is_group: isGroup,
      items: {
        ...props.passivo.items,
        total: props.passivo.total
      }
    },
    dre: {
      ...props.dre,
      id,
      is_group: isGroup,
      items: {
        ...props.dre.items,
        total: props.dre.total
      }
    },
    mutacao: {
      ...props.mutacao,
      id,
      is_group: isGroup,
      items: {
        ...props.mutacao.items,
        total: props.mutacao.total
      }
    },
    fundos: {
      data: props.fundos,
      id,
      is_group: isGroup
    },
    parceiros: {
      data: props.parceiros,
      id,
      is_group: isGroup
    },
    ratingParameters: {
      data: props.rating
    }
  }
  reduxData.id = document.replace(/\D/g, '')
  reduxData.is_group = isGroup

  if (reduxData.cnpj) {
    reduxData.cnpj = reduxData.cnpj.replace(/\D/g, '')
  }

  const token = await getIdToken()

  await axios
    .post(
      `${API_BASE_URL}/spread/put`,
      {
        ...reduxData,
        is_group: isGroup
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    .then(response => {
      return response
    })
}

export const listVersions = async ({ cnpj, isGroup }) => {
  const token = await getIdToken()

  const { data } = await axios.get(`${API_BASE_URL}/list-versions`, {
    params: {
      cnpj,
      is_group: !!isGroup
    },
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  return data.versions
}

export const deleteGroup = async ({ id, authKey }) => {
  const token = await getIdToken()
  const { data: responseData } = await axios.post(
    `${API_BASE_URL}/remove-json-group`,
    {
      id
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'x-auth-key': authKey
      }
    }
  )

  return responseData
}
