import React, { Component } from 'react'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'

import { MdTrendingUp } from 'react-icons/md'

import { saveAllData } from '../../lib/save-data'

import * as SidebarActions from '../../redux/store/actions/sidebar'
import * as AtivoActions from '../../redux/store/actions/ativo'

import Sidebar from './../../components/sidebar'
import PageTitle from './../../components/pageTitle'
import Footer from './../../components/footer'
import ItemCurrency from './../../components/item-currency'
import TotalAtivo from './../../components/total/ativo'

import ChartToogle from './../../components/chart-toogle'
import SubTotalToogle from './../../components/subtotal'

import 'react-toastify/dist/ReactToastify.css'
import './ativo.css'

class Ativo extends Component {
  constructor (props) {
    super(props)
    this.props.clickSidebar(1)
    this.sumTotal()
    this.state = { showChart: true }
  }

  async componentDidUpdate () {
    // console.log('PAGINA ATIVO', this.props.dados, this.props.ativo)
    if (this.props.dados.datas.length !== this.props.ativo.total) {
      // console.log('DATAS DIFERENTES');
    }
  }

  async clickSave () {
    await Swal.fire({
      title: 'Salvar Informações',
      text: 'Deseja salvar as informações inseridas ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#525ad0',
      cancelButtonColor: '#272c84'
    }).then(async result => {
      if (!result.dismiss) {
        await saveAllData(this.props).then(res => {
          toast('Informações salvas no servidor', {
            className: 'toast-background',
            bodyClassName: 'toast-body',
            progressClassName: 'toast-progress',
            position: 'top-right',
            hideProgressBar: true,
            style: {
              borderRadius: '10px'
            }
          })
        })
      }
    })
  }

  async sumTotal () {
    await this.props.sumSubTotal(this.props.dados.datas)
  }

  changeChart () {
    this.setState({
      showChart: !this.state.showChart
    })
  }

  render () {
    const { ativo, sidebar } = this.props

    return (
      <div className='container-fluid min-vh-100' style={{ padding: '0' }}>
        <div className='row min-vh-100 no-gutters'>
          <Sidebar />
          <div className='content col-auto'>
            <PageTitle
              title='Ativo da Empresa'
              icon={MdTrendingUp}
              btnSalvar={this.clickSave.bind(this)}
              redux_data={ativo}
            />
            <div className='bodyContent' style={{ paddingTop: '0em' }}>
              <ItemCurrency
                item={ativo.items.ativo_circulante}
                keyName='ativo_circulante'
                name='Ativo Circulante'
                type='ativo'
                redux_data={ativo}
                hide_chart={!sidebar.showChart}
                // hide_total={!sidebar.showSubtotal}
              />

              <div
                className={sidebar.showSubtotal ? 'divisor' : 'divisor-mini'}
              />

              <ItemCurrency
                item={ativo.items.realizavel_longo_prazo}
                keyName='realizavel_longo_prazo'
                name='Realizável a longo prazo'
                type='ativo'
                redux_data={ativo}
                hide_chart={!sidebar.showChart}
                // hide_total={!sidebar.showSubtotal}
              />

              <div
                className={sidebar.showSubtotal ? 'divisor' : 'divisor-mini'}
              />

              <ItemCurrency
                item={ativo.items.investimentos}
                keyName='investimentos'
                name='Investimentos'
                type='ativo'
                redux_data={ativo}
                hide_chart={!sidebar.showChart}
                // hide_total={!sidebar.showSubtotal}
              />

              <div
                className={sidebar.showSubtotal ? 'divisor' : 'divisor-mini'}
              />

              <ItemCurrency
                item={ativo.items.imobilizado}
                keyName='imobilizado'
                name='Imobilizado'
                type='ativo'
                redux_data={ativo}
                hide_chart={!sidebar.showChart}
                // hide_total={!sidebar.showSubtotal}
              />

              <div
                className={sidebar.showSubtotal ? 'divisor' : 'divisor-mini'}
              />

              <ItemCurrency
                item={ativo.items.ativo_diferido}
                keyName='ativo_diferido'
                name='Diferido'
                type='ativo'
                redux_command='ativo_permanente'
                redux_data={ativo}
                hide_total
                hide_chart
              />

              <div
                className={sidebar.showSubtotal ? 'divisor' : 'divisor-mini'}
              />

              <ItemCurrency
                item={ativo.items.ativo_permanente}
                keyName='ativo_permanente'
                name='Permanente'
                type='ativo'
                redux_data={ativo}
                hide_chart
              />
            </div>

            <ChartToogle />
            <SubTotalToogle />
            <TotalAtivo />
            <Footer />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  dados: state.dados,
  ativo: state.ativo,
  passivo: state.passivo,
  dre: state.dre,
  mutacao: state.mutacao,
  fundos: state.fundos,
  parceiros: state.parceiros,
  rating: state.rating,

  sidebar: state.sidebar,
  isGroup: state.sidebar.isGroup
})

const mapDispatchToProps = dispatch => ({
  dispatch,
  clickSidebar: item => dispatch(SidebarActions.clickSidebar(item)),
  sumSubTotal: dados => dispatch(AtivoActions.sumSubTotal(dados))
})

export default connect(mapStateToProps, mapDispatchToProps)(Ativo)
