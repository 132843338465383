export function updateSimpleData (key, value) {
  return {
    type: 'ATIVO_UPDATE_SIMPLE_DATA',
    key,
    value
  }
}

export function updateTableExcel (el, keyNameItems, keyItem, keyData, value) {
  return {
    type: 'ATIVO_UPDATE_TABLE_EXCEL',
    el,
    key_name_items: keyNameItems,
    key_item: keyItem,
    key_data: keyData,
    value
  }
}

export function sumTotalEl (el, keyNameItems, keyData, value) {
  return {
    type: 'ATIVO_SUM_VALUES_FROM_ELEMENT',
    el,
    key_name_items: keyNameItems,
    key_data: keyData,
    value
  }
}

export function superSum (keyNameItems, keyItem, keyData, value) {
  return {
    type: 'ATIVO_SUM_ALL_VALUES_BY_DATE',
    key_name_items: keyNameItems,
    key_item: keyItem,
    key_data: keyData,
    value
  }
}

export function porcentage (keyData) {
  return {
    type: 'ATIVO_PORCENTAGE_ALL_ELEMENTS',
    key_data: keyData
  }
}

export function updateProgress (keyName) {
  return {
    type: 'ATIVO_UPDATE_PROGRESS',
    keyName
  }
}

export function sumSubTotal (datas) {
  return {
    type: 'SUM_SUB_TOTAL_WITHOUT_VALUE',
    datas
  }
}

export function updateDate (value) {
  return {
    type: 'UPDATE_DATE_ATIVO',
    value
  }
}

export function removeData (value, totalDates) {
  return {
    type: 'REMOVE_DATE',
    value,
    total_dates: totalDates
  }
}

export function ativopermanente (keyData) {
  return {
    type: 'ATIVO_PERMANENTE',
    key_data: keyData
  }
}

export function bootstrap (data, totalDataEl) {
  return {
    type: 'BOOTSTRAP_ATIVO',
    data,
    total_data_el: totalDataEl
  }
}

export function addDataAtivo () {
  return {
    type: 'ADD_NEW_DATE'
  }
}

export function updateFromReduxStyle (data, totalDataEl) {
  return {
    type: 'ATIVO/UPDATE_FROM_REDUX_STYLE',
    data,
    total_data_el: totalDataEl
  }
}
