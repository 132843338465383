import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import { useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'

import { factoryDate, formatWithTimezone, isValidDocument } from '../../utils'

import 'react-toastify/dist/ReactToastify.css'
import './pageTitle.css'

export default function PageTitle ({ title, btnSalvar, btnExternal }) {
  const versions = useSelector(state => state.versions.versions)
  const [dados, isGroup] = useSelector(state => [
    state.dados,
    state.sidebar.isGroup
  ])
  const location = useLocation()

  const hasMoreThanOneVersionAndIsNotGroup = versions.length > 1 && !isGroup

  const { dateVersion, cnpj, path } = useMemo(() => {
    const urlParams = new URLSearchParams(location.search)
    const dateVersion = urlParams.get('dateVersion')

    const cnpjFromUrl = location.pathname.split('/')[2]
    const cnpjFromRedux = (dados.cnpj || '').replace(/\D/g, '')

    const cnpj = cnpjFromRedux || cnpjFromUrl

    const path = location.pathname.split('/')[1]

    return { dateVersion, cnpj, path }
  }, [location, dados.cnpj])

  const handleSeeVersions = async () => {
    const sortedVersions = versions.sort((a, b) => {
      const dateA = new Date(a.version)
      const dateB = new Date(b.version)

      return dateB.getTime() - dateA.getTime()
    })
    const indexOfDateVersion = sortedVersions.findIndex(
      item => item.version === dateVersion
    )
    const { value: index } = await Swal.fire({
      title: 'Selecione uma versão',
      input: 'select',
      inputOptions: sortedVersions.map(item =>
        formatWithTimezone(
          factoryDate(item.version + 'Z'),
          'dd/MM/yyyy HH:mm:ss'
        )
      ),
      inputValue: indexOfDateVersion !== -1 ? indexOfDateVersion : 0
    })

    if (isNaN(parseInt(index))) {
      return
    }

    const value = sortedVersions[index]

    window.location.href = `/${path}/${cnpj}?dateVersion=${value.version}`
  }

  return (
    <div>
      <div
        className='pageTitle row justify-content-between no-gutters'
        style={
          dados.datas.length < 1
            ? { backgroundColor: '#141414', color: 'white' }
            : { backgroundColor: 'white', color: '#535ad0' }
        }
      >
        <div className='col-auto titlePage'>
          {title} {isGroup ? <span className='text-danger'> (GRUPO)</span> : ''}
        </div>

        {dados.datas.length < 1 && (
          <div
            className='col-auto'
            style={{ fontSize: '18px', paddingTop: '3px' }}
          >
            É NECESSÁRIO ADICIONAR DATAS PARA SALVAR
          </div>
        )}
        <div
          className={
            hasMoreThanOneVersionAndIsNotGroup
              ? 'col-4 d-flex flex-row'
              : 'col-3 d-flex flex-row'
          }
          style={{ height: '40px', marginRight: '80px', gap: '10px' }}
        >
          {hasMoreThanOneVersionAndIsNotGroup && (
            <button
              className='btn btn-primary btn-sm'
              onClick={handleSeeVersions}
            >
              outras versões
            </button>
          )}

          <div className='btn-group btn-group-sm' role='group'>
            {btnSalvar !== false && (
              <button
                className='btn btn-secondary btn-sm btn-salvar'
                onClick={async () => {
                  if (isValidDocument(dados.cnpj)) {
                    await btnSalvar()

                    if (dateVersion) {
                      window.location.href = `/${path}/${cnpj}`
                    }
                  } else {
                    toast('Documento inválido', {
                      className: 'toast-red',
                      bodyClassName: 'toast-body',
                      progressClassName: 'toast-progress',
                      position: 'top-right',
                      hideProgressBar: true,
                      style: {
                        borderRadius: '10px'
                      }
                    })
                  }
                }}
                disabled={
                  dados.cnpj !== '' && dados.datas && dados.datas.length < 1
                }
              >
                {dateVersion ? 'restaurar esta versão' : 'salvar informações'}
              </button>
            )}

            {btnExternal && btnExternal}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}
