import React, { Component } from 'react'
import CurrencyInput from 'react-currency-input'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ChartPie from './chart-pie'

import { MdLink } from 'react-icons/md'

import * as AtivoActions from '../../redux/store/actions/ativo'
import * as PassivoActions from '../../redux/store/actions/passivo'
import * as MutacaoActions from '../../redux/store/actions/mutacao'
import * as DREActions from '../../redux/store/actions/dre'

import './item-currency.css'

class ItemCurrency extends Component {
  constructor (props) {
    super(props)

    this.state = {
      dados: this.props.dados.datas,
      items: this.props.item.items,
      item: this.props.item
    }

    this.state.items.map(item => {
      // check if this item has data object
      const checkData = 'datas' in item
      if (!checkData || (item.datas && item.datas.length === 0)) {
        const tempArray = []
        this.props.dados.datas.forEach(() => {
          tempArray.push({ value: 0, percent: 0 })
        })
        item.datas = tempArray
      }

      return item
    })

    this.state.item.total =
      this.state.item.total === undefined || this.state.item.total.length === 0
        ? new Array(this.props.dados.datas.length).fill(0)
        : this.state.item.total
  }

  enabledMutacao = async () => {
    await this.props.dispatch(MutacaoActions.patrimonioInicialEnabledEdit())
  }

  checkEnabled = (keyData, keyItem, noEdit) => {
    if (this.props.type === 'mutacao' && keyData === 0 && keyItem === 0) {
      return false
    } else {
      return noEdit
    }
  }

  render () {
    const {
      item,
      name,
      dados,
      keyName,
      ativo,
      passivo,
      mutacao,
      dre,
      type,
      dispatch,
      sidebar
    } = this.props
    const getDate = date => {
      const d = new Date(date)
      return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear()
    }

    let reduxData = ''

    switch (type) {
      case 'ativo':
        reduxData = ativo
        break
      case 'passivo':
        reduxData = passivo
        break
      case 'mutacao':
        reduxData = mutacao
        // this.enabledMutacao();
        break
      case 'dre':
        reduxData = dre
        break
      default:
        reduxData = ativo // warning react
    }

    return (
      <div className='row justify-content-start col-12'>
        <h4 className={sidebar.showSubtotal ? 'name-item' : 'name-item-mini'}>
          {name}
        </h4>
        <div className='col-12' />
        <div className='col-12 row justify-content-start'>
          <table className='col-auto'>
            <thead>
              <tr>
                <td />
                {dados.datas.map((date, key) => (
                  <td className='col-name' key={key}>
                    {getDate(date.data)}
                  </td>
                ))}
              </tr>
            </thead>
            <tbody>
              {item.items.map((el, keyItem) => (
                <tr key={keyItem}>
                  <td
                    className={
                      sidebar.showSubtotal ? 'nameItem' : 'nameItemMini'
                    }
                  >
                    {el.item}
                  </td>
                  {(dados.datas && dados.datas.length === 0) ||
                  (reduxData.items[keyName].items[keyItem].datas &&
                    reduxData.items[keyName].items[keyItem].datas.length ===
                      0) ? (
                    <td className='alert-dados'>
                      <Link to='/dados'>
                        <MdLink />
                        <span className='link-click'>
                          adicione os períodos na página dados
                        </span>
                      </Link>
                    </td>
                  ) : (
                    dados.datas.map((data, keyData) => (
                      <td key={keyData}>
                        <div className='input-group'>
                          <div
                            className={
                              sidebar.showSubtotal
                                ? 'input-group-prepend'
                                : 'input-group-prepend box-mini'
                            }
                          >
                            <span
                              className={
                                sidebar.showSubtotal
                                  ? 'input-group-text'
                                  : 'input-group-text box-mini'
                              }
                            >
                              R$
                            </span>
                          </div>
                          <CurrencyInput
                            disabled={this.checkEnabled(
                              keyData,
                              keyItem,
                              reduxData.items[keyName].items[keyItem].noedit
                            )}
                            value={
                              reduxData.items[keyName].items[keyItem].datas &&
                              reduxData.items[keyName].items[keyItem].datas[
                                keyData
                              ]
                                ? reduxData.items[keyName].items[keyItem]
                                  .datas &&
                                reduxData.items[keyName].items[keyItem].datas[
                                  keyData
                                ].value
                                : 0
                            }
                            allowNegative
                            decimalSeparator=','
                            thousandSeparator='.'
                            precision='0'
                            className={
                              sidebar.showSubtotal
                                ? 'inputCurrency'
                                : 'iputCurrency box-mini-currency'
                            }
                            onChange={async (valueComma, valueDot, event) => {
                              // console.log('values', valueComma, valueDot, event);

                              switch (type) {
                                // ATIVO
                                case 'ativo':
                                  await dispatch(
                                    AtivoActions.updateTableExcel(
                                      el,
                                      keyName,
                                      keyItem,
                                      keyData,
                                      valueDot
                                    )
                                  )

                                  break

                                // PASSIVO
                                case 'passivo':
                                  await dispatch(
                                    PassivoActions.updateTableExcel(
                                      el,
                                      keyName,
                                      keyItem,
                                      keyData,
                                      valueDot
                                    )
                                  )
                                  break

                                // MUTACAO
                                case 'mutacao':
                                  await dispatch(
                                    MutacaoActions.updateTableExcel(
                                      el,
                                      keyName,
                                      keyItem,
                                      keyData,
                                      valueDot
                                    )
                                  )
                                  break

                                // DRE
                                case 'dre':
                                  await dispatch(
                                    DREActions.updateTableExcel(
                                      el,
                                      keyName,
                                      keyItem,
                                      keyData,
                                      valueDot
                                    )
                                  )
                                  break
                                default:
                                  break
                              }
                            }}
                            onBlur={async event => {
                              const valueNumber = event.target.value
                              switch (type) {
                                // ATIVO
                                case 'ativo':
                                  await dispatch(
                                    AtivoActions.sumTotalEl(
                                      el,
                                      keyName,
                                      keyData,
                                      valueNumber
                                    )
                                  )
                                  await dispatch(
                                    AtivoActions.superSum(
                                      keyName,
                                      keyItem,
                                      keyData,
                                      valueNumber
                                    )
                                  )
                                  await dispatch(
                                    AtivoActions.porcentage(keyData)
                                  )
                                  await dispatch(
                                    AtivoActions.ativopermanente(keyData)
                                  )

                                  // if (this.props.redux_command) {
                                  // 	switch (this.props.redux_command) {
                                  // 		case 'ativo_permanente':
                                  // 			await dispatch(
                                  // 				AtivoActions.ativopermanente(
                                  // 					key_data
                                  // 				)
                                  // 			);
                                  // 			break;
                                  // 		default:
                                  // 			break;
                                  // 	}
                                  // }
                                  break

                                // PASSIVO
                                case 'passivo':
                                  if (this.props.redux_command) {
                                    switch (this.props.redux_command) {
                                      case 'total_bancos':
                                        await dispatch(
                                          PassivoActions.totalBancos(keyData)
                                        )
                                        await dispatch(
                                          PassivoActions.passivoCirculante(
                                            keyData
                                          )
                                        )
                                        await dispatch(
                                          PassivoActions.superSum(
                                            keyName,
                                            keyItem,
                                            keyData,
                                            valueNumber
                                          )
                                        )
                                        break

                                      case 'passivo_circulante':
                                        await dispatch(
                                          PassivoActions.passivoCirculante(
                                            keyData
                                          )
                                        )
                                        await dispatch(
                                          PassivoActions.superSum(
                                            keyName,
                                            keyItem,
                                            keyData,
                                            valueNumber
                                          )
                                        )
                                        break
                                      default:
                                        break
                                    }
                                  } else {
                                    await dispatch(
                                      PassivoActions.sumTotalEl(
                                        el,
                                        keyName,
                                        keyData,
                                        valueNumber
                                      )
                                    )
                                    await dispatch(
                                      PassivoActions.superSum(
                                        keyName,
                                        keyItem,
                                        keyData,
                                        valueNumber
                                      )
                                    )
                                    await dispatch(
                                      PassivoActions.porcentage(keyData)
                                    )
                                    await dispatch(
                                      PassivoActions.exigivelTotal(keyData)
                                    )
                                  }

                                  await dispatch(
                                    PassivoActions.porcentage(keyData)
                                  )

                                  await dispatch(
                                    PassivoActions.superSum(
                                      keyName,
                                      keyItem,
                                      keyData,
                                      valueNumber
                                    )
                                  )

                                  await dispatch(
                                    PassivoActions.ativoPassivoCheck(
                                      ativo.total[keyData],
                                      keyData
                                    )
                                  )

                                  break

                                // MUTACAO
                                case 'mutacao':
                                  await dispatch(
                                    MutacaoActions.sumTotalEl(
                                      el,
                                      keyName,
                                      keyData,
                                      valueNumber
                                    )
                                  )

                                  await dispatch(
                                    MutacaoActions.patrimonioLiquidoInicial(
                                      dados.datas,
                                      keyData
                                    )
                                  )

                                  await dispatch(
                                    MutacaoActions.lucroLiquidoExercicio(
                                      dre.total,
                                      keyData
                                    )
                                  )

                                  await dispatch(
                                    MutacaoActions.superSum(
                                      keyName,
                                      keyItem,
                                      keyData,
                                      valueNumber
                                    )
                                  )

                                  await dispatch(
                                    MutacaoActions.diferencaPatrimonioLiquido(
                                      passivo.items.patrimonio_liquido.total[
                                        keyData
                                      ],
                                      keyData
                                    )
                                  )

                                  await dispatch(
                                    MutacaoActions.porcentage(keyData)
                                  )

                                  break

                                // DRE
                                case 'dre':
                                  await dispatch(
                                    DREActions.receitaLiquidaSubtract(keyData)
                                  )
                                  await dispatch(DREActions.lucrobruto(keyData))
                                  await dispatch(
                                    DREActions.lucrooperacional(keyData)
                                  )
                                  await dispatch(
                                    DREActions.lucrooperacionalliquido(keyData)
                                  )
                                  await dispatch(
                                    DREActions.lucroliquido(keyData)
                                  )

                                  await dispatch(
                                    DREActions.minusTotalEl(
                                      el,
                                      keyName,
                                      keyData,
                                      valueNumber
                                    )
                                  )

                                  await dispatch(DREActions.supertotal(keyData))

                                  await dispatch(DREActions.porcentage(keyData))

                                  break
                                default:
                                  break
                              }
                            }}
                          />
                          <div
                            className={
                              sidebar.showSubtotal
                                ? 'input-group-append'
                                : 'input-group-append box-mini'
                            }
                          >
                            <span
                              className={
                                sidebar.showSubtotal
                                  ? 'input-group-text porcentage percent-sufix'
                                  : 'input-group-text porcentage percent-sufix box-mini-blue'
                              }
                            >
                              {reduxData.items[keyName].items[keyItem].datas &&
                              reduxData.items[keyName].items[keyItem].datas[
                                keyData
                              ]
                                ? reduxData.items[keyName].items[keyItem]
                                  .datas &&
                                reduxData.items[keyName].items[keyItem].datas[
                                  keyData
                                ].percent
                                : '0%'}
                            </span>
                          </div>
                        </div>
                      </td>
                    ))
                  )}
                </tr>
              ))}

              {this.props.hide_total ? null : (
                <tr className='total-row'>
                  <td className='nameItem'>Total</td>

                  {dados.datas.map((data, key) => (
                    <td className='total-value' key={key}>
                      <span className='total-prefix'>R$</span>

                      <span className='total-sufix'>
                        {reduxData.items[keyName] &&
                        reduxData.items[keyName].total.length > 0 &&
                        reduxData.items[keyName].total[key]
                          ? reduxData.items[keyName].total[key].toLocaleString(
                            'pt-BR'
                          )
                          : 0}
                      </span>
                    </td>
                  ))}
                </tr>
              )}
            </tbody>
          </table>

          {type === 'dre' || this.props.hide_chart ? null : (
            <div className='col-4 charts'>
              <ChartPie data={item} />
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  dados: state.dados,
  ativo: state.ativo,
  passivo: state.passivo,
  mutacao: state.mutacao,
  dre: state.dre,
  sidebar: state.sidebar
})

export default connect(mapStateToProps)(ItemCurrency)
