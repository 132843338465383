import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import InputMask from 'react-input-mask'
import Swal from 'sweetalert2'
import { ToastContainer, toast } from 'react-toastify'

import { MdDataUsage, MdAddCircle, MdRemoveCircle } from 'react-icons/md'

import 'react-datepicker/dist/react-datepicker.css'
import 'react-toastify/dist/ReactToastify.css'

import * as SidebarActions from '../../redux/store/actions/sidebar'
import * as DadosActions from '../../redux/store/actions/dados_datas'

import { ResetSpread, deleteGroup } from '../../services/index'
import { consolidateGroup } from '../../services/automaticSpread'

import { saveAllData } from '../../lib/save-data'

import { dynamicMask, isValidDocument } from '../../utils'

import Sidebar from '../../components/sidebar'
import PageTitle from '../../components/pageTitle'
import Footer from '../../components/footer'

export default function ConsolidacaoPage () {
  const dispatch = useDispatch()
  const allState = useSelector(state => ({
    dados: state.dados,
    ativo: state.ativo,
    passivo: state.passivo,
    dre: state.dre,
    mutacao: state.mutacao,
    fundos: state.fundos,
    parceiros: state.parceiros,
    api: state.api,
    rating: state.rating
  }))

  useEffect(() => {
    dispatch(SidebarActions.clickSidebar(10))
  }, [])

  const handleDelete = async () => {
    const url = new URL(window.location)
    const documentoFromUrl = url.pathname.split('/')[2]

    const { isConfirmed, value } = await Swal.fire({
      title: 'Apagar Consolidação ?',
      text: 'Ao apagar a consolidação, todos os dados do SPREAD CONSOLIDADO serão perdidos. Informe a senha de permissão para continuar',
      input: 'text',
      inputLabel: 'Digite "apagar" para confirmar',
      inputPlaceholder: 'digite aqui a senha de permissão',
      confirmButtonText: 'Apagar',
      confirmButtonColor: '#dc3545',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      inputValidator: value => {
        if (!value || (value || '').length === 0) {
          return 'Você deve informar a senha de permissão'
        }
      },
      focusDeny: true
    })

    if (!isConfirmed || !value) {
      return
    }

    try {
      Swal.fire({
        title: 'Apagando consolidação',
        text: 'Por favor aguarde alguns segundos',
        onBeforeOpen: async () => {
          Swal.showLoading()
        }
      })

      await deleteGroup({ id: documentoFromUrl, authKey: value })
      Swal.hideLoading()
      Swal.close()

      await Swal.fire({
        title: 'Consolidação apagada',
        text: 'A página será recarregada',
        icon: 'success'
      })

      window.location.reload()
    } catch (err) {
      Swal.hideLoading()
      Swal.close()

      console.error(err)
      const message =
        err.response?.data.message ?? 'Por favor contate o suporte do BOA NOTA'
      Swal.fire({
        title: 'Erro ao apagar consolidação',
        text: message,
        icon: 'error'
      })
    }
  }

  const handleReset = async () => {
    const result = await Swal.fire({
      title: 'Resetar Spread ?',
      text: 'Esse spread terá seus dados apagados para que possa reiniciar a inserção. Para confirmar insira a senha master de controle',
      input: 'password',
      inputPlaceholder: 'digite aqui a senha master',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      cancelButtonColor: 'red',
      confirmButtonColor: 'red',
      icon: 'question'
    })

    if (result.value === 'c8ag565z') {
      Swal.fire({
        title: 'Spread Reset',
        text: 'Por favor aguarde alguns segundos',
        icon: 'info',
        onBeforeOpen: () => {
          Swal.showLoading()
          ResetSpread({
            dados: allState.dados,
            isGroup: allState.isGroup
          }).then(resSuccess => {
            Swal.fire({
              title: 'Spread Reiniciado',
              text: 'Este spread foi reiniciado com sucesso, sua página será atualizada',
              icon: 'info',
              allowOutsideClick: false
            }).then(res => {
              window.location.reload()
            })
          })
        }
      })
    } else {
      Swal.fire(
        'Senha Incorreta',
        'Este SPREAD não foi resetado. Em caso de dúvidas contate o suporte BOA NOTA',
        'error'
      )
    }
  }

  const handleSave = async () => {
    const result = await Swal.fire({
      title: 'Salvar Informações',
      text: 'Deseja salvar as informações inseridas ? As datas inseridas serão bloqueadas para evitar erros nos cálculos',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#525ad0',
      cancelButtonColor: '#272c84'
    })
    try {
      if (!result.dismiss) {
        let error = 0
        if (allState.dados.nome_empresa === '') {
          error++
        }
        if (allState.dados.cnpj === '') {
          error++
        }

        if (error > 0) {
          Swal.fire(
            'Erro no formulário',
            'Existem alguns campos sem preenchimento, por favor revise',
            'error'
          )
        } else {
          dispatch(DadosActions.lockDates())
          await saveAllData({ ...allState, dispatch }).then(async () => {
            dispatch(DadosActions.saveDataServer())

            toast('Informações salvas no servidor', {
              className: 'toast-background',
              bodyClassName: 'toast-body',
              progressClassName: 'toast-progress',
              position: 'top-right',
              hideProgressBar: true,
              style: {
                borderRadius: '10px'
              }
            })
          })
        }
      }
    } catch (e) {
      toast(
        <div>
          <div>Erro servidor</div>
          <div
            style={{
              borderTop: '1px dashed rgba(255,255,255,1)',
              fontSize: '12px',
              padding: '5px 0',
              marginTop: '5px'
            }}
          >
            Por favor contate o suporte do BOA NOTA.
          </div>
        </div>,
        {
          className: 'toast-red',
          bodyClassName: 'toast-body',
          progressClassName: 'toast-progress',
          position: 'top-right',
          hideProgressBar: true,
          style: {
            borderRadius: '10px',
            background: 'red'
          }
        }
      )
    }
  }

  const renderResetButton = () => {
    return (
      <button
        className='btn btn-danger btn-sm btn-reset'
        style={{ height: '40px' }}
        onClick={() => handleReset()}
      >
        resetar spread
      </button>
    )
  }

  const updateData = (key, value, status) =>
    dispatch(DadosActions.updateData(key, value, status))

  const onChangeDocumento = (value, index) => {
    const newCnpjs = (allState.dados.empresas_consolidadas || []).map(
      (item, i) => {
        if (i === index) {
          return {
            value,
            valid: true // deixa sempre válido para só exibir o erro no blur
          }
        }
        return item
      }
    )

    updateData('empresas_consolidadas', newCnpjs, false)
  }

  const onBlurDocumento = (value, index) => {
    const valid = isValidDocument(value)

    const newCnpjs = (allState.dados.empresas_consolidadas || []).map(
      (item, i) => {
        if (i === index) {
          return {
            value,
            valid
          }
        }
        return item
      }
    )

    updateData('empresas_consolidadas', newCnpjs, false)
  }

  const onRemoveDocumento = index => {
    const newCnpjs = (allState.dados.empresas_consolidadas || []).filter(
      (_, i) => i !== index
    )
    updateData('empresas_consolidadas', newCnpjs, false)
  }

  const onAddDocumento = () => {
    const newCnpjs = [
      ...(allState.dados.empresas_consolidadas || []),
      { value: '', valid: true }
    ]
    updateData('empresas_consolidadas', newCnpjs, false)
  }

  const onConsolidate = async () => {
    const documento = allState.dados.cnpj
    try {
      if (!isValidDocument(documento || '')) {
        Swal.fire({
          title: 'Erro',
          text: 'Informe um CNPJ / CPF válido',
          type: 'error',
          confirmButtonText: 'Ok'
        })

        return
      }
      Swal.fire({
        title: 'Consolidando spread',
        text: 'Aguarde um momento...',
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading()
        }
      })

      await consolidateGroup({
        targetDocument: documento,
        empresasConsolidadas: allState.dados.empresas_consolidadas
      })

      Swal.close()

      await Swal.fire({
        title: 'Spread consolidado com sucesso',
        text: 'Ao confirmar a página será recarregada',
        type: 'success',
        confirmButtonText: 'Ok'
      })
      window.location.reload()
    } catch (err) {
      Swal.close()
      const message =
        err.response?.data?.message || err.message || 'Erro desconhecido'
      console.error(err)

      Swal.fire({
        title: 'Ocorreu um erro ao tentar consolidar o grupo',
        text: message,
        type: 'error',
        confirmButtonText: 'Ok'
      })
    }
  }

  return (
    <div className='container-fluid min-vh-100' style={{ padding: '0' }}>
      <div className='row min-vh-100 no-gutters'>
        <Sidebar />
        <div className='content col-auto'>
          <PageTitle
            title='Consolidação'
            icon={MdDataUsage}
            btnSalvar={handleSave}
            redux_data={allState.dados}
            btnExternal={renderResetButton()}
          />
          <div className='bodyContent'>
            <form className='col-12 form-inline'>
              <div
                className='h4'
                style={{ fontWeight: 'bold', color: 'rgb(83, 90, 208)' }}
              >
                CNPJs / CPFs do grupo
              </div>
              <div className='container-fluid'>
                {allState.dados.groupExists && (
                  <div
                    style={{
                      fontSize: '11px',
                      marginTop: '0px',
                      marginBottom: '30px'
                    }}
                  >
                    <b>
                      <span className='text-danger fw-bold'>ATENÇÃO:</span> Já
                      existe um spread
                    </b>{' '}
                    consolidado com esse documento como controlador. <br />
                    Consolidar um novo grupo <b>pode sobrescrever os dados</b>.
                  </div>
                )}
                {(allState.dados.empresas_consolidadas || []).map(
                  (item, index) => (
                    <div className='row my-2' key={index.toString()}>
                      <div className='col-12'>
                        <InputMask
                          mask={dynamicMask(item.value)}
                          maskPlaceholder='*'
                          type='text'
                          placeholder='CNPJ ou CPF'
                          className={
                            'form-control ' + (item.valid ? '' : 'error_input ')
                          }
                          value={item.value}
                          onChange={event =>
                            onChangeDocumento(event.target.value, index)
                          }
                          onBlur={event =>
                            onBlurDocumento(event.target.value, index)
                          }
                        />
                        <button
                          type='button'
                          className='btn btn-sm btn-outline-primary ml-2'
                          onClick={() => onRemoveDocumento(index)}
                        >
                          <MdRemoveCircle
                            style={{ position: 'relative', top: '-3px' }}
                          />
                        </button>
                      </div>
                    </div>
                  )
                )}
                <div className='row mt-4'>
                  <div className='col-12'>
                    <button
                      className='btn btn-sm btn-outline-primary'
                      type='button'
                      onClick={onAddDocumento}
                      style={{ minWidth: 170 }}
                    >
                      <span style={{ fontWeight: 'bold' }}>
                        <MdAddCircle style={{ fontSize: '20px' }} /> adicionar
                        documento
                      </span>
                    </button>
                  </div>
                </div>
                <div className='row mt-4'>
                  <div className='col-12'>
                    <button
                      className='btn btn-sm btn-outline-primary'
                      type='button'
                      style={{ minWidth: 170 }}
                      disabled={
                        (allState.dados.empresas_consolidadas || []).length ===
                        0
                      }
                      onClick={onConsolidate}
                    >
                      <span style={{ fontWeight: 'bold' }}>
                        consolidar spreads
                      </span>
                    </button>
                    <button
                      className='btn btn-sm btn-outline-danger ml-3'
                      type='button'
                      style={{ minWidth: 170 }}
                      disabled={!allState.dados.groupExists}
                      onClick={handleDelete}
                    >
                      <span style={{ fontWeight: 'bold' }}>
                        apagar consolidação
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <Footer />
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}
