import JSON_TABLE from '../../../assets/table-parceiros.json'

export default function data (state = JSON_TABLE, action) {
  if (action.type === 'PARCEIROS/UPDATE_FROM_REDUX_STYLE') {
    return [...action.data]
  }

  if (action.type === 'BOOTSTRAP_PARCEIROS') {
    if (action.data) {
      action.data.map((parceiro, key) => {
        // parceiro.data_cadastro = '';
        // parceiro.ultima_operacao = '';
        return parceiro
      })

      return action.data
    }
  }

  if (action.type === 'ADD_ITEM') {
    return state.concat(action.item)
  }

  if (action.type === 'CHANGE_ITEM') {
    const newState = [...state]
    newState[action.index][action.keyValue] = action.value

    return newState
  }

  if (action.type === 'REMOVE_ITEM') {
    let newState = [...state]
    newState = newState.filter((item, key) => key !== action.index)

    return newState
  }
  return state
}
