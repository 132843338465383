import React, { useState } from 'react'
import { connect } from 'react-redux'

import {
  MdDataUsage,
  MdTrendingDown,
  MdTrendingUp,
  MdDoneAll,
  MdLastPage,
  MdBookmarkBorder,
  MdTrendingFlat,
  MdDashboard,
  MdExitToApp,
  MdGroup,
  MdFlag
} from 'react-icons/md'

import * as SidebarActions from '../../redux/store/actions/sidebar'
import { logout, VERSION } from '../../utils'

import LogoTXT from './../../assets/img/logo-text.svg'
import ItemMenuSidebar from './ItemMenuSidebar'

import './sidebar.css'

const Sidebar = ({
  isGroup,
  activeMenu,
  dados,
  ativo,
  passivo,
  mutacao,
  dre
}) => {
  const [logoRef, setLogoRef] = useState()

  const progress = menuParam => {
    if (typeof menuParam === 'object') {
      const progress = Object.entries(menuParam.progress)
      const dataTrue = progress.filter(item => {
        if (item[1] === true) {
          return item
        }
        return false
      })

      const porcent = (dataTrue.length / progress.length) * 100
      return Math.ceil(porcent) + '%'
    }

    return menuParam
  }

  return (
    <div className={`sidebar ${isGroup ? 'group' : 'individual'}`}>
      <div className='logo' ref={newRef => setLogoRef(newRef)}>
        <img src={LogoTXT} id='logoBOANOTA' alt='Boa Nota' />
        <span
          style={{
            color: 'white',
            textAlign: 'center',
            fontSize: '10px',
            fontWeight: '700',
            position: 'relative',
            top: ' -5px'
          }}
        >
          v. {VERSION}
        </span>
      </div>
      <div
        className='menuContainer'
        style={
          logoRef
            ? {
              height: `calc(100vh - ${logoRef.clientHeight}px)`,
              marginTop: `${logoRef.clientHeight}px`
            }
            : {}
        }
      >
        <ul className='menu'>
          <ItemMenuSidebar
            name='Dados'
            icon={MdDataUsage}
            url='/dados'
            position={0}
            // clickMenu={clickMenu}
            progress={progress(dados)}
            active={activeMenu === 0}
          />
          {dados.datas && dados.datas.length > 0 && dados.is_saved && (
            <ItemMenuSidebar
              name='Ativos'
              icon={MdTrendingUp}
              url='/ativo'
              position={1}
              // clickMenu={clickMenu}
              progress={progress(ativo)}
              active={activeMenu === 1}
            />
          )}
          {dados.datas && dados.datas.length > 0 && dados.is_saved && (
            <ItemMenuSidebar
              name='Passivo'
              icon={MdTrendingDown}
              url='/passivo'
              position={2}
              // clickMenu={clickMenu}
              progress={progress(passivo)}
              active={activeMenu === 2}
            />
          )}
          {dados.datas && dados.datas.length > 0 && dados.is_saved && (
            <ItemMenuSidebar
              name='DRE'
              icon={MdTrendingFlat}
              url='/dre'
              position={3}
              // clickMenu={clickMenu}
              progress={progress(mutacao)}
              active={activeMenu === 4}
            />
          )}
          {dados.datas && dados.datas.length > 0 && dados.is_saved && (
            <ItemMenuSidebar
              name='Mutação'
              icon={MdDoneAll}
              url='/mutacao'
              position={4}
              // clickMenu={clickMenu}
              progress={progress(mutacao)}
              active={activeMenu === 3}
            />
          )}
          <ItemMenuSidebar
            name='Fundos'
            icon={MdLastPage}
            url='/fundos'
            position={5}
            // clickMenu={clickMenu}
            progress={progress('0%')}
            active={activeMenu === 5}
          />
          <ItemMenuSidebar
            name='Parceiros'
            icon={MdBookmarkBorder}
            url='/parceiros'
            position={6}
            // clickMenu={clickMenu}
            progress={progress('0%')}
            active={activeMenu === 6}
          />

          {dre.total &&
            dre.total.length > 0 &&
            dados.datas &&
            dados.datas.length > 0 &&
            dados.is_saved && (
              <ItemMenuSidebar
                name='Highlights'
                icon={MdDashboard}
                url='/highlight'
                position={7}
                // clickMenu={clickMenu}
                progress={progress('0%')}
                active={activeMenu === 7}
              />
            )}
          <ItemMenuSidebar
            name='Rating'
            url='/rating'
            icon={MdFlag}
            position={8}
            active={activeMenu === 8}
          />
          {!isGroup && (
            <ItemMenuSidebar
              name='Consolidação'
              url='/consolidacao'
              icon={MdGroup}
              position={8}
              active={activeMenu === 10}
            />
          )}
          <ItemMenuSidebar
            name='Sair'
            icon={MdExitToApp}
            onClick={logout}
            position={9}
          />
        </ul>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  activeMenu: state.sidebar.activeMenu,
  isGroup: state.sidebar.isGroup,
  dados: state.dados,
  ativo: state.ativo,
  passivo: state.passivo,
  mutacao: state.mutacao,
  dre: state.dre,
  parceiros: state.parceiros,
  fundos: state.fundos
})

const mapDispatchToProps = dispatch => ({
  clickSidebar: item => dispatch(SidebarActions.clickSidebar(item))
})

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
