import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'

import * as DadosActions from '../redux/store/actions/dados_datas'
import * as AtivoActions from '../redux/store/actions/ativo'
import * as PassivoActions from '../redux/store/actions/passivo'
import * as MutacaoActions from '../redux/store/actions/mutacao'
import * as DREActions from '../redux/store/actions/dre'
import * as FundosActions from '../redux/store/actions/fundos'
import * as ParceirosActions from '../redux/store/actions/parceiros'
import * as HighlightsActions from '../redux/store/actions/highlights'
import { bootstrapRating } from '../redux/store/reducers/rating'
import { setOriginalData } from '../redux/store/concorrencia'
import { setVersions } from '../redux/store/versions'

import { listVersions, loadAllDataFromAPI } from './index'

const UpdateRedux = props => {
  const { dispatch } = props

  useEffect(() => {
    const url = new URL(window.location)
    const documentFromUrl = url.pathname.split('/')[2]

    const urlParams = new URLSearchParams(window.location.search)
    const dateVersion = urlParams.get('dateVersion')

    if (documentFromUrl) {
      Swal.fire({
        title: 'Carregando informações'
      })
      Swal.showLoading()
      loadData({
        dispatch,
        cnpj: documentFromUrl,
        isGroup: false,
        withMessage: true,
        dateVersion
      })
    }
  }, [dispatch])

  useEffect(() => {
    const url = new URL(window.location)
    const cnpjFromUrl = url.pathname.split('/')[2]

    if (!cnpjFromUrl) {
      return
    }

    listVersions({ cnpj: cnpjFromUrl, isGroup: false })
      .then(versions => {
        dispatch(setVersions(versions))
      })
      .catch(err => {
        console.error(err)
      })
  }, [dispatch])

  return <div />
}

export const loadData = async ({
  cnpj,
  dispatch,
  isGroup,
  withMessage,
  dateVersion
}) => {
  try {
    const res = await fetchDataAndParse({ cnpj, isGroup, dateVersion })

    const totalDatas = res.dados.datas.length

    const originalData = JSON.parse(JSON.stringify(res))

    dispatch(setOriginalData(originalData))

    await dispatch(DadosActions.bootstrap(res.dados))
    await dispatch(AtivoActions.bootstrap(res.ativos, totalDatas))
    await dispatch(PassivoActions.bootstrap(res.passivos, totalDatas))
    await dispatch(MutacaoActions.bootstrap(res.mutacao, totalDatas))
    await dispatch(DREActions.bootstrap(res.dre, totalDatas))
    await dispatch(FundosActions.bootstrap(res.fundos))
    await dispatch(ParceirosActions.bootstrap(res.parceiros))
    dispatch(bootstrapRating(res.ratingParameters ?? {}))

    if (res.highlights) {
      await dispatch(HighlightsActions.bootstrap(res.highlights))
    }

    if (Swal.isLoading()) {
      Swal.close()
    }
  } catch (err) {
    console.error(err)
    const errorMessage = err.message
    await dispatch(DadosActions.bootstrap(null, 0))
    await dispatch(AtivoActions.bootstrap(null, 0))

    if (!withMessage) {
      throw err
    }

    if (errorMessage.includes('Document not found')) {
      Swal.fire(
        'Documento não encontrado',
        'O documento não foi encontrado. Por favor, verifique o CNPJ / CPF e tente novamente. Se o problema persistir, entre em contato com o suporte do Boa Nota.',
        'error'
      )
    } else if (errorMessage.includes('User group not authorized')) {
      Swal.fire(
        'Acesso negado',
        'Você não tem permissão para acessar o spread. Por favor, entre em contato com o suporte do Boa Nota para liberar o acesso.',
        'error'
      )
    } else {
      Swal.fire(
        'ERRO',
        'Desculpe ocorreu um erro no servidor, contate o suporte do Boa Nota',
        'error'
      )
    }
  }
}

export const fetchDataAndParse = async ({ cnpj, isGroup, dateVersion }) => {
  const res = await loadAllDataFromAPI(cnpj, isGroup, dateVersion)

  if (res.dados.datas.length > 0) {
    res.dados.datas.map(data => {
      data.enabled = true
      return data
    })
  }

  const mergedDados = {
    ...res.dados,
    groupExists: res.groupExists,
    empresas_consolidadas: isGroup
      ? res.dados.empresas_consolidadas
      : res.empresasConsolidadasGrupo
  }

  // if (mergedDados.data_fundacao === '') {
  //   mergedDados.data_fundacao = new Date()
  // }

  res.dados = mergedDados

  if (res.highlights && res.highlights_corporate && res.geracao_caixa_livre) {
    const high = {
      highlights: res.highlights,
      highlights_corporate: res.highlights_corporate,
      geracao_caixa_livre: res.geracao_caixa_livre
    }

    res.highlights = high
    delete res.highlights_corporate
    delete res.geracao_caixa_livre
  }

  return res
}

const mapStateToProps = state => ({
  ativo: state.ativo,
  dados: state.dados,
  sidebar: state.sidebar,
  passivo: state.passivo,
  mutacao: state.mutacao,
  fundos: state.fundos,
  highlights: state.highlights
})

export default connect(mapStateToProps)(UpdateRedux)
