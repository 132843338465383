// Action Types
export const Types = {
  SET_VERSIONS: 'VERSIONS/SET_VERSIONS'
}

// Reducer
const initialState = {
  versions: []
}

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case Types.SET_VERSIONS: {
      return {
        ...state,
        versions: action.payload || []
      }
    }
    default:
      return state
  }
}

// Action Creators
export function setVersions (data) {
  return {
    type: Types.SET_VERSIONS,
    payload: data
  }
}
