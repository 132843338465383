import Swal from 'sweetalert2'

import { save } from '../services'

import { loadData } from '../services/updateREDUX'

export const saveAllData = async props => {
  try {
    Swal.fire({
      title: 'Salvando dados',
      text: 'Aguarde um momento...',
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      }
    })
    const dispatch = props.dispatch
    const documento = props.dados.cnpj
    const isGroup = props.isGroup
    await save(documento, props, isGroup)
    await loadData({ dispatch, cnpj: documento, isGroup, withMessage: false })
    Swal.close()
  } catch (error) {
    console.error(error)
    Swal.fire({
      title: 'Erro',
      text: 'Ocorreu um erro ao salvar os dados',
      type: 'error',
      confirmButtonText: 'Ok'
    })
  }
}
