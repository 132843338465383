import React, { Component } from 'react'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'

import { MdTrendingDown } from 'react-icons/md'

import { saveAllData } from '../../lib/save-data'

import Sidebar from './../../components/sidebar'
import PageTitle from './../../components/pageTitle'
import TotalPassivo from '../../components/total/passivo'
import Footer from './../../components/footer'
import ItemCurrency from './../../components/item-currency'
import ChartToogle from './../../components/chart-toogle'
import SubTotalToogle from './../../components/subtotal'

import * as SidebarActions from '../../redux/store/actions/sidebar'
import * as PassivoActions from '../../redux/store/actions/passivo'

import 'react-toastify/dist/ReactToastify.css'

class Passivo extends Component {
  constructor (props) {
    super(props)
    this.props.clickSidebar(2)
    this.sumTotal()
  }

  async clickSave () {
    Swal.fire({
      title: 'Salvar Informações',
      text: 'Deseja salvar as informações inseridas ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#525ad0',
      cancelButtonColor: '#272c84'
    }).then(async result => {
      if (!result.dismiss) {
        const url = new URL(window.location)
        const documentFromUrl = url.pathname.split('/')[2]

        this.props.dados.cnpj = this.props.dados.cnpj
          ? this.props.dados.cnpj
          : documentFromUrl
        await saveAllData(this.props).then(res => {
          toast('Informações salvas no servidor', {
            className: 'toast-background',
            bodyClassName: 'toast-body',
            progressClassName: 'toast-progress',
            position: 'top-right',
            hideProgressBar: true,
            style: {
              borderRadius: '10px'
            }
          })
        })
      }
    })
  }

  async sumTotal () {
    await this.props.sumSubTotal(this.props.dados.datas)
  }

  render () {
    const { passivo, sidebar } = this.props

    return (
      <div className='container-fluid min-vh-100' style={{ padding: '0' }}>
        <div className='row min-vh-100 no-gutters'>
          <Sidebar />
          <div className='content col-auto'>
            <PageTitle
              title='Passivo da Empresa'
              icon={MdTrendingDown}
              btnSalvar={this.clickSave.bind(this)}
              redux_data={passivo}
            />
            <div className='bodyContent' style={{ paddingTop: '0em' }}>
              <ItemCurrency
                item={passivo.items.total_bancos}
                keyName='total_bancos'
                name='Total Bancos'
                type='passivo'
                redux_data={passivo}
                hide_chart={!this.props.sidebar.showChart}
                redux_command='total_bancos'
              />

              <div
                className={sidebar.showSubtotal ? 'divisor' : 'divisor-mini'}
              />

              <ItemCurrency
                item={passivo.items.passivo_circulante}
                keyName='passivo_circulante'
                name='Passivo Circulante'
                type='passivo'
                redux_data={passivo}
                hide_chart={!this.props.sidebar.showChart}
                redux_command='passivo_circulante'
              />

              <div
                className={sidebar.showSubtotal ? 'divisor' : 'divisor-mini'}
              />

              <ItemCurrency
                item={passivo.items.exigivel_longo_prazo}
                keyName='exigivel_longo_prazo'
                name='Exigível Longo Prazo'
                type='passivo'
                redux_data={passivo}
                // redux_command="exigivel_total"
                hide_chart={!this.props.sidebar.showChart}
              />

              <div
                className={sidebar.showSubtotal ? 'divisor' : 'divisor-mini'}
              />

              <ItemCurrency
                item={passivo.items.exigivel_total}
                keyName='exigivel_total'
                name='Exigível Total'
                type='passivo'
                redux_data={passivo}
                hide_chart
              />

              <div
                className={sidebar.showSubtotal ? 'divisor' : 'divisor-mini'}
              />

              <ItemCurrency
                item={passivo.items.resultado_exercicio_futuro}
                keyName='resultado_exercicio_futuro'
                name='Resultado do Exercício Futuro'
                type='passivo'
                redux_data={passivo}
                hide_chart
                hide_total
              />

              <div
                className={sidebar.showSubtotal ? 'divisor' : 'divisor-mini'}
              />

              <ItemCurrency
                item={passivo.items.patrimonio_liquido}
                keyName='patrimonio_liquido'
                name='Patrimonio Líquido'
                type='passivo'
                redux_data={passivo}
                hide_chart={!this.props.sidebar.showChart}
              />

              <ItemCurrency
                item={passivo.items.ativo_passivo_check}
                keyName='ativo_passivo_check'
                name='Ativo - Passivo (Check)'
                type='passivo'
                redux_data={passivo}
                hide_chart
                redux_command='ativopassivoCheck'
              />
            </div>
            <ChartToogle />
            <SubTotalToogle />

            <TotalPassivo />
            <Footer />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  dados: state.dados,
  ativo: state.ativo,
  passivo: state.passivo,
  dre: state.dre,
  mutacao: state.mutacao,
  fundos: state.fundos,
  parceiros: state.parceiros,
  rating: state.rating,

  sidebar: state.sidebar,
  isGroup: state.sidebar.isGroup
})

const mapDispatchToProps = dispatch => ({
  dispatch,
  clickSidebar: item => dispatch(SidebarActions.clickSidebar(item)),
  sumSubTotal: dados => dispatch(PassivoActions.sumSubTotal(dados))
})

export default connect(mapStateToProps, mapDispatchToProps)(Passivo)
