export function bootstrap (data) {
  return {
    type: 'BOOTSTRAP_HIGHLIGHTS',
    data
  }
}

export function updateFromReduxStyle (data) {
  return {
    type: 'HIGHLIGHTS/UPDATE_FROM_REDUX_STYLE',
    data
  }
}
