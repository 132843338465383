import React, { useLayoutEffect } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'

import Dados from './pages/dados'
import Ativo from './pages/ativo'
import Passivo from './pages/passivo'
import Mutacao from './pages/mutacao'
import Fundos from './pages/fundos'
import Parceiro from './pages/parceiro'
import DRE from './pages/dre'
import p404 from './pages/404'
import Highlights from './pages/highlights'
import ChangeLog from './pages/changelog'
import RatingPage from './pages/rating'
import ConsolidacaoPage from './pages/consolidacao'

import UpdateREDUX from './services/updateREDUX'
import Concorrencia from './services/concorrencia'

const Router = () => {
  const history = useHistory()
  console.group('ROOT EVENT')
  console.groupEnd()

  useLayoutEffect(() => {
    // eslint-disable-next-line
    const pathname = sessionStorage.getItem('pathname')
    if (history && pathname) {
      history.push(pathname)
    }
  }, [])

  return (
    <>
      <UpdateREDUX />
      <Concorrencia />
      <Switch>
        <Route path={['/dados', '/dados/:documento']} component={Dados} />
        <Route path={['/ativo', '/ativo/:documento']} component={Ativo} />
        <Route path={['/passivo', '/passivo/:documento']} component={Passivo} />
        <Route path={['/mutacao', '/mutacao/:documento']} component={Mutacao} />
        <Route path={['/dre', '/dre/:documento']} component={DRE} />
        <Route path={['/fundos', '/fundos/:documento']} component={Fundos} />
        <Route
          path={['/parceiros', '/parceiros/:documento']}
          component={Parceiro}
        />
        <Route
          path={['/highlight', '/highlight/:documento']}
          component={Highlights}
        />
        <Route
          path={['/rating', '/rating/:documento']}
          component={RatingPage}
        />
        <Route
          path={['/consolidacao', '/consolidacao/:documento']}
          component={ConsolidacaoPage}
        />
        <Route path='/log' component={ChangeLog} />

        <Route path='*' component={p404} />
      </Switch>
    </>
  )
}

export default Router
