import React, { Component } from 'react'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import { MdTrendingDown, MdAdd, MdLink } from 'react-icons/md'

import { saveAllData } from '../../lib/save-data'

import Sidebar from '../../components/sidebar'
import PageTitle from '../../components/pageTitle'
import Footer from '../../components/footer'
import ParceiroItem from './../../components/parceiro-item'

import * as SidebarActions from '../../redux/store/actions/sidebar'
import * as ParceiroActions from '../../redux/store/actions/parceiros'
import './parceiro.css'

import 'react-toastify/dist/ReactToastify.css'

class Parceiro extends Component {
  constructor (props) {
    super(props)
    this.props.clickSidebar(6)
  }

  async clickSave () {
    Swal.fire({
      title: 'Salvar Informações',
      text: 'Deseja salvar as informações inseridas ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#525ad0',
      cancelButtonColor: '#272c84'
    }).then(async result => {
      if (!result.dismiss) {
        await saveAllData(this.props).then(res => {
          toast('Informações salvas no servidor', {
            className: 'toast-background',
            bodyClassName: 'toast-body',
            progressClassName: 'toast-progress',
            position: 'top-right',
            hideProgressBar: true,
            style: {
              borderRadius: '10px'
            }
          })
        })
      }
    })
  }

  addItem () {
    const tempItem = {
      parceiro: '',
      data_cadastro: new Date(),
      limite: '',
      ultima_operacao: new Date(),
      risco_vcto: '',
      vencidos: '',
      vop: '',
      liquidez: '',
      atrasos: '',
      recompra: '',
      cartorio: '',
      obs: ''
    }

    this.props.addItem(tempItem)
  }

  render () {
    const { ativo, dados, parceiros } = this.props
    return (
      <div className='container-fluid min-vh-100' style={{ padding: '0' }}>
        <div className='row min-vh-100 no-gutters'>
          <Sidebar />
          <div className='content col-auto'>
            <PageTitle
              title='Parceiros de Mercado • Consulta'
              icon={MdTrendingDown}
              btnSalvar={this.clickSave.bind(this)}
              redux_data={ativo}
            />
            <div
              className='bodyContent'
              style={{ paddingBottom: '1em', marginTop: '40px' }}
            />

            {!dados.nome_empresa || !dados.cnpj ? (
              <div
                className='row justify-content-start no-gutters error-page'
                style={{ marginLeft: '30px' }}
              >
                <Link to='/dados'>
                  <MdLink />
                  <span className='link-click'>
                    insira primeiro as informações na página dados. clique aqui
                  </span>
                </Link>
              </div>
            ) : (
              <div
                className='row justify-content-start no-gutters'
                style={{ marginLeft: '30px', marginBottom: '20%' }}
              >
                <div className='field-name col-12 company-name'>
                  {dados.nome_empresa}
                </div>
                <div className='field-name col-12 cnpj'>{dados.cnpj}</div>
                <div className='col-auto col-header text-right'>
                  <div className='field-cnpj'>Parceiro</div>
                  <div className='field-cadastro'>Data Cadastro</div>
                  <div className='field-limite'>Limite</div>
                  <div className='field-operacao'>Última Operação</div>
                  <div className='field-riscos'>Risco / Vcto</div>
                  <div className='field-vencidos'>Vencidos</div>
                  <div className='field-vop'>VOP</div>
                  <div className='field-liquidez'>Liquidez Pontual</div>
                  <div className='field-atraso'>Atrasos</div>
                  <div className='field-recompra'>Recompra</div>
                  <div className='field-cartorio'>Cartório</div>
                  <div className='field-obs'>Observações</div>
                </div>

                {parceiros.map((parceiro, key) => {
                  if (parceiro) {
                    return (
                      <ParceiroItem
                        parceiro={parceiro}
                        indexItem={key}
                        key={key}
                      />
                    )
                  }
                  return null
                })}

                <div className='col-auto col-button'>
                  <button
                    className='button-add btn-primary btn-lg'
                    onClick={() => this.addItem()}
                  >
                    <MdAdd size={30} />
                  </button>
                </div>
              </div>
            )}

            {/* <AtivoTotal /> */}

            <Footer />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  dados: state.dados,
  ativo: state.ativo,
  passivo: state.passivo,
  dre: state.dre,
  mutacao: state.mutacao,
  fundos: state.fundos,
  parceiros: state.parceiros,
  rating: state.rating,

  sidebar: state.sidebar,
  isGroup: state.sidebar.isGroup
})

const mapDispatchToProps = dispatch => ({
  dispatch,
  clickSidebar: item => dispatch(SidebarActions.clickSidebar(item)),
  addItem: item => dispatch(ParceiroActions.addItem(item))
})

export default connect(mapStateToProps, mapDispatchToProps)(Parceiro)
