import { takeLatest } from 'redux-saga/effects'

import { Types as ConcorrenciaTypes } from '../store/concorrencia'

import { computeDiffOriginal, computeDiffUpstream } from './concorrencia'

function * mySaga () {
  yield takeLatest(ConcorrenciaTypes.SET_ORIGINAL_DATA, computeDiffOriginal)
  yield takeLatest(ConcorrenciaTypes.SET_UPSTREAM_DATA, computeDiffUpstream)
}

export default mySaga
