import { factoryDate } from '../../../utils'

export function addData (dataItem) {
  return {
    type: 'DADOS_DATA_ADD',
    data_item: dataItem
  }
}

export function changeBalanco (data, item) {
  return {
    type: 'CHANGE_BALANCO_ITEM',
    data,
    item
  }
}

export function changeAuditado (data, item) {
  return {
    type: 'TOGGLE_AUDITADO',
    data,
    item
  }
}

export function changeConsolidado (data, item) {
  return {
    type: 'TOGGLE_CONSOLIDADO',
    data,
    item
  }
}

export function changeCombinado (data, item) {
  return {
    type: 'TOGGLE_COMBINADO',
    data,
    item
  }
}

export function removeData (item) {
  return {
    type: 'REMOVE_DATA',
    item
  }
}

export function increaseProgress () {
  return {
    type: 'INCREASE_PROGRESS',
    value: 1
  }
}

export function decreaseProgress () {
  return {
    type: 'DECREASE_PROGRESS',
    value: 1
  }
}

export function saveData (data) {
  return {
    type: 'SAVE_DATA',
    data
  }
}

export function updateData (key, value, status) {
  return {
    type: 'UPDATE_DATA',
    key,
    value,
    status
  }
}

export function updateSimpleData (key, value) {
  return {
    type: 'UPDATE_SIMPLE_DATA',
    key,
    value
  }
}

export function bootstrap (data) {
  return {
    type: 'BOOTSTRAP',
    data
  }
}

export function saveDataServer () {
  return {
    type: 'SAVE_DATA_SERVER'
  }
}

export function lockDates () {
  return {
    type: 'LOCK_DATES'
  }
}

export function updateFromReduxStyle (data) {
  if (data) {
    data.datas = (data.datas || []).map(item => ({
      ...item,
      data: factoryDate(item.data)
    }))
    data.fundacao = data.fundacao ? factoryDate(data.fundacao) : null
  }

  return {
    type: 'DADOS/UPDATE_FROM_REDUX_STYLE',
    data
  }
}
