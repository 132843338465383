import { parseReduxData } from '../../lib/parse-redux'

import { hashObject } from '../../utils'

// Action Types
export const Types = {
  RESET: 'CONCORRENCIA/RESET',
  SET_ORIGINAL_DATA: 'CONCORRENCIA/SET_ORIGINAL_DATA',
  SET_CURRENT_DATA: 'CONCORRENCIA/SET_CURRENT_DATA',
  SET_UPSTREAM_DATA: 'CONCORRENCIA/SET_UPSTREAM_DATA'
}

// Reducer
const initialState = {
  originalData: {
    data: undefined,
    hash: undefined
  },
  currentData: {
    data: undefined,
    hash: undefined
  },
  upstreamData: {
    data: undefined,
    hash: undefined
  }
}

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case Types.SET_ORIGINAL_DATA: {
      const hash = hashObject(parseReduxData(action.payload))

      return {
        ...state,
        originalData: {
          data: action.payload,
          hash
        }
      }
    }
    case Types.SET_CURRENT_DATA: {
      const hash = hashObject(parseReduxData(action.payload))

      return {
        ...state,
        currentData: {
          data: action.payload,
          hash
        }
      }
    }
    case Types.SET_UPSTREAM_DATA: {
      const hash = hashObject(parseReduxData(action.payload))

      return {
        ...state,
        upstreamData: {
          data: action.payload,
          hash
        }
      }
    }
    default:
      return state
  }
}

// Action Creators
export function setOriginalData (data) {
  return {
    type: Types.SET_ORIGINAL_DATA,
    payload: data
  }
}

export function setCurrentData (data) {
  return {
    type: Types.SET_CURRENT_DATA,
    payload: data
  }
}

export function setUpstreamData (data) {
  return {
    type: Types.SET_UPSTREAM_DATA,
    payload: data
  }
}
