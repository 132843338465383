import JSON_TABLE from '../../../assets/table-ativo.json'

const progress = { ...JSON_TABLE }
Object.keys(progress).forEach(function (item) {
  progress[item] = false
})

delete progress.total

const INITIAL_STATE = {
  items: JSON_TABLE,
  progress,
  progress_show_tooltip: false
}

// console.group('ATIVO REDUX');
// console.log(INITIAL_STATE);
// console.groupEnd();

const fillIfsEmpty = (originValue, newValue) => {
  const newArray = Array(newValue).fill({ value: 0, percent: 0 })
  if (originValue.length === 0) {
    return newArray
  } else if (originValue.length !== newValue) {
    for (let key = 0; key < newValue; key++) {
      if (!originValue[key]) {
        originValue[key] = { value: 0, percent: 0 }
      }
    }
    return originValue
  }
  return originValue
}

const totalIfEmpty = (originValue, newValue) => {
  const newArray = Array(newValue).fill(0)
  if (originValue.length === 0) {
    return newArray
  } else if (originValue.length !== newValue) {
    for (let key = 0; key < newValue; key++) {
      if (!originValue[key]) {
        originValue[key] = 0
      }
    }
    return originValue
  }
  return originValue
}

export default function data (state = INITIAL_STATE, action) {
  if (action.type === 'ATIVO/UPDATE_FROM_REDUX_STYLE') {
    if (action.data) {
      action.data.total = totalIfEmpty(action.data.total, action.total_data_el)
      action.data.items.total = totalIfEmpty(
        action.data.total,
        action.total_data_el
      )
      action.data.items.ativo_circulante.total = totalIfEmpty(
        action.data.items.ativo_circulante.total,
        action.total_data_el
      )
      action.data.items.realizavel_longo_prazo.total = totalIfEmpty(
        action.data.items.realizavel_longo_prazo.total,
        action.total_data_el
      )
      action.data.items.investimentos.total = totalIfEmpty(
        action.data.items.investimentos.total,
        action.total_data_el
      )
      action.data.items.imobilizado.total = totalIfEmpty(
        action.data.items.imobilizado.total,
        action.total_data_el
      )
      action.data.items.ativo_diferido.total = totalIfEmpty(
        action.data.items.ativo_diferido.total,
        action.total_data_el
      )
      action.data.items.ativo_permanente.total = totalIfEmpty(
        action.data.items.ativo_permanente.total,
        action.total_data_el
      )
    }
    return {
      ...state,
      ...action.data
    }
  }

  if (action.type === 'BOOTSTRAP_ATIVO') {
    if (action.data) {
      const ativoCirculante = [
        {
          datas: fillIfsEmpty(
            action.data.ativo_circulante.caixa_bancos_aplic_liq_imediata,
            action.total_data_el
          ),
          id_back: 'caixa_bancos_aplic_liq_imediata',
          item: 'Caixa, Bancos, Aplic. de Liq. Imediata',
          type: '+',
          id: 0
        },
        {
          datas: fillIfsEmpty(
            action.data.ativo_circulante.titulo_valores_mobiliarios,
            action.total_data_el
          ),
          id_back: 'titulo_valores_mobiliarios',
          item: 'Título de Valores Mobiliários',
          type: '+',
          id: 1
        },
        {
          datas: fillIfsEmpty(
            action.data.ativo_circulante.contas_receber_clientes_circulante,
            action.total_data_el
          ),
          id_back: 'contas_receber_clientes_circulante',
          item: 'Contas a Receber de Clientes',
          type: '+',
          id: 2
        },
        {
          datas: fillIfsEmpty(
            action.data.ativo_circulante.provisao_devedores_duvidosos,
            action.total_data_el
          ),
          id_back: 'provisao_devedores_duvidosos',
          item: '(-)Provisão para Devedores Duvidosos',
          type: '-',
          id: 3
        },
        {
          datas: fillIfsEmpty(
            action.data.ativo_circulante.estoques,
            action.total_data_el
          ),
          id_back: 'estoques',
          item: 'Estoques',
          type: '+',
          id: 4
        },
        {
          datas: fillIfsEmpty(
            action.data.ativo_circulante.adiantamento_fornecedores,
            action.total_data_el
          ),
          id_back: 'adiantamento_fornecedores',
          item: 'Adiantamento a Fornecedores',
          type: '+',
          id: 5
        },
        {
          datas: fillIfsEmpty(
            action.data.ativo_circulante.impostos_recuperar,
            action.total_data_el
          ),
          id_back: 'impostos_recuperar',
          item: 'Impostos a Recuperar',
          type: '+',
          id: 6
        },
        {
          datas: fillIfsEmpty(
            action.data.ativo_circulante.partes_relacionadas_circulante,
            action.total_data_el
          ),
          id_back: 'partes_relacionadas_circulante',
          item: 'Partes Relacionadas',
          type: '+',
          id: 7
        },
        {
          datas: fillIfsEmpty(
            action.data.ativo_circulante.outros_ativos_operacionais,
            action.total_data_el
          ),
          id_back: 'outros_ativos_operacionais',
          item: 'Outros Ativos Operacionais',
          type: '+',
          id: 8
        },
        {
          datas: fillIfsEmpty(
            action.data.ativo_circulante.outros_ativos_nao_operacionais,
            action.total_data_el
          ),
          id_back: 'outros_ativos_operacionais',
          item: 'Outros Ativos não Operacionais',
          type: '+',
          id: 9
        },
        {
          datas: fillIfsEmpty(
            action.data.ativo_circulante.despesas_pagas_antecipadamente,
            action.total_data_el
          ),
          id_back: 'outros_ativos_nao_operacionais',
          item: 'Desp. Pagas Antecipadamente',
          type: '+',
          id: 10
        }
      ]

      const realizavelLongoPrazo = [
        {
          datas: fillIfsEmpty(
            action.data.realizavel_longo_prazo
              .partes_relacionadas_realizavel_lp,
            action.total_data_el
          ),
          id_back: 'partes_relacionadas_realizavel_lp',
          item: 'Partes Relacionadas',
          type: '+',
          id: 11
        },
        {
          datas: fillIfsEmpty(
            action.data.realizavel_longo_prazo.bens_para_venda,
            action.total_data_el
          ),
          id_back: 'bens_para_venda',
          item: 'Bens para Venda',
          type: '+',
          id: 12
        },
        {
          datas: fillIfsEmpty(
            action.data.realizavel_longo_prazo
              .contas_receber_clientes_realizavel_lp,
            action.total_data_el
          ),
          id_back: 'contas_receber_clientes_realizavel_lp',
          item: 'Contas a Receber de Clientes',
          type: '+',
          id: 13
        },
        {
          datas: fillIfsEmpty(
            action.data.realizavel_longo_prazo.outras_contas,
            action.total_data_el
          ),
          id_back: 'outras_contas',
          item: 'Outras Contas',
          type: '+',
          id: 14
        }
      ]

      const investimentos = [
        {
          datas: fillIfsEmpty(
            action.data.investimentos.particip_contr_coligada,
            action.total_data_el
          ),
          id_back: 'particip_contr_coligada',
          item: 'Particip. Contr/Coligada',
          type: '+',
          id: 15
        },
        {
          datas: fillIfsEmpty(
            action.data.investimentos.outros_investimentos,
            action.total_data_el
          ),
          id_back: 'outros_investimentos',
          item: 'Outros Investimentos',
          type: '+',
          id: 16
        }
      ]

      const imobilizado = [
        {
          datas: fillIfsEmpty(
            action.data.imobilizado.imoveis_equipamentos,
            action.total_data_el
          ),
          id_back: 'imoveis_equipamentos',
          item: 'Imóveis/Equipamentos',
          type: '+',
          id: 17
        },
        {
          datas: fillIfsEmpty(
            action.data.imobilizado.imobilizacao_em_andamento,
            action.total_data_el
          ),
          id_back: 'imobilizacao_em_andamento',
          item: 'Imobilização em Andamento',
          type: '+',
          id: 18
        },
        {
          datas: fillIfsEmpty(
            action.data.imobilizado.deprec_amort_exaustao,
            action.total_data_el
          ),
          id_back: 'deprec_amort_exaustao',
          item: '(-) Deprec.  Amort. Exaustão.',
          type: '+',
          id: 19
        }
      ]

      const ativoDiferido = [
        {
          datas: fillIfsEmpty(
            action.data.ativo_diferido.ativo_diferido,
            action.total_data_el
          ),
          id_back: 'ativo_diferido',
          item: 'Ativo diferido',
          type: '+',
          id: 20
        }
      ]

      const prevState = {
        ...state,
        items: {
          ...state.items,
          ativo_circulante: {
            ...state.items.ativo_circulante,
            items: ativoCirculante,
            total: totalIfEmpty(
              action.data.ativo_circulante.total,
              action.total_data_el
            )
          },
          realizavel_longo_prazo: {
            ...state.items.realizavel_longo_prazo,
            items: realizavelLongoPrazo,
            total: totalIfEmpty(
              action.data.realizavel_longo_prazo.total,
              action.total_data_el
            )
          },
          investimentos: {
            ...state.items.investimentos,
            items: investimentos,
            total: totalIfEmpty(
              action.data.investimentos.total,
              action.total_data_el
            )
          },
          imobilizado: {
            ...state.items.imobilizado,
            items: imobilizado,
            total: totalIfEmpty(
              action.data.imobilizado.total,
              action.total_data_el
            )
          },
          ativo_diferido: {
            ...state.items.ativo_diferido,
            items: ativoDiferido,
            total: totalIfEmpty(
              action.data.ativo_diferido.total,
              action.total_data_el
            )
          },
          ativo_permanente: {
            ...state.items.ativo_permanente,
            total: totalIfEmpty(
              action.data.ativo_permanente.total,
              action.total_data_el
            )
          },
          total: totalIfEmpty(action.data.total, action.total_data_el)
        },
        total: totalIfEmpty(action.data.total, action.total_data_el)
      }
      return prevState
    } else {
      const prevState = { ...state }
      prevState.items.ativo_circulante.items.map(
        item =>
          (item.datas = Array(action.total_data_el).fill({
            value: 0,
            percent: 0
          }))
      )
      prevState.items.ativo_circulante.total = Array(action.total_data_el).fill(
        0
      )

      prevState.items.realizavel_longo_prazo.items.map(
        item =>
          (item.datas = Array(action.total_data_el).fill({
            value: 0,
            percent: 0
          }))
      )
      prevState.items.realizavel_longo_prazo.total = Array(
        action.total_data_el
      ).fill(0)

      prevState.items.investimentos.items.map(
        item =>
          (item.datas = Array(action.total_data_el).fill({
            value: 0,
            percent: 0
          }))
      )
      prevState.items.investimentos.total = Array(action.total_data_el).fill(0)

      prevState.items.imobilizado.items.map(
        item =>
          (item.datas = Array(action.total_data_el).fill({
            value: 0,
            percent: 0
          }))
      )
      prevState.items.imobilizado.total = Array(action.total_data_el).fill(0)

      prevState.items.ativo_diferido.items.map(
        item =>
          (item.datas = Array(action.total_data_el).fill({
            value: 0,
            percent: 0
          }))
      )
      prevState.items.ativo_diferido.total = Array(action.total_data_el).fill(0)

      prevState.items.ativo_permanente.items.map(
        item =>
          (item.datas = Array(action.total_data_el).fill({
            value: 0,
            percent: 0
          }))
      )
      prevState.items.ativo_permanente.total = Array(action.total_data_el).fill(
        0
      )

      prevState.items.total = Array(action.total_data_el).fill(0)
      prevState.total = Array(action.total_data_el).fill(0)

      return prevState
    }
  }

  if (action.type === 'ATIVO_UPDATE_SIMPLE_DATA') {
    return {
      ...state,
      [action.key]: action.value,
      progress: {
        ...state.progress,
        [action.key]: true
      }
    }
  }

  if (action.type === 'ATIVO_UPDATE_TABLE_EXCEL') {
    return {
      ...state,
      progress: {
        ...state.progress,
        [action.key_name_items]: true
      },
      items: {
        ...state.items,
        [action.key_name_items]: {
          ...state.items[action.key_name_items],

          items: state.items[action.key_name_items].items.map((item, key) =>
            key === action.key_item
              ? {
                ...item,
                datas:
                  state.items[action.key_name_items].items[action.key_item]
                    .datas &&
                  state.items[action.key_name_items].items[
                    action.key_item
                  ].datas.map((data, keyData) =>
                    keyData === action.key_data
                      ? {
                        ...data,
                        value: action.value
                      }
                      : data
                  )
              }
              : item
          )
        }
      }
    }
  }

  if (action.type === 'ATIVO_SUM_VALUES_FROM_ELEMENT') {
    let total = 0

    state.items[action.key_name_items].items.map(item => {
      const value = item.datas[action.key_data]
        ? item.datas[action.key_data].value
        : 0
      total += value
      return null
    })

    const value = parseFloat(action.value)
    const percent = total !== 0 ? (value * 100) / total : 0

    return {
      ...state,
      items: {
        ...state.items,
        [action.key_name_items]: {
          ...state.items[action.key_name_items],

          total: state.items[action.key_name_items].total.map(
            (itemTotal, keyTotal) =>
              keyTotal === action.key_data ? total : itemTotal
          ),
          items: state.items[action.key_name_items].items.map((item, key) =>
            key === action.key_item
              ? {
                ...item,
                datas: state.items[action.key_name_items].items[
                  action.key_item
                ].datas.map((data, keyData) =>
                  keyData === action.key_data
                    ? {
                      ...data,
                      percent
                    }
                    : data
                )
              }
              : item
          )
        }
      }
    }
  }

  if (action.type === 'ATIVO_SUM_ALL_VALUES_BY_DATE') {
    const ativoCirculante = state.items.ativo_circulante.total[action.key_data]
    const realizavelLp =
      state.items.realizavel_longo_prazo.total[action.key_data]
    const investimentos = state.items.investimentos.total[action.key_data]
    const imoveisEquipamentos =
      state.items.imobilizado.items[0].datas[action.key_data].value
    const imobilizacaAndamento =
      state.items.imobilizado.items[1].datas[action.key_data].value
    const depreciacaoAmortizacao =
      state.items.imobilizado.items[2].datas[action.key_data].value
    const ativoDiferido = state.items.ativo_diferido.total[action.key_data]

    const totalFinal =
      ativoCirculante +
      realizavelLp +
      investimentos +
      imoveisEquipamentos +
      imobilizacaAndamento +
      depreciacaoAmortizacao +
      ativoDiferido

    const object2Array = Object.entries(state.items)
    object2Array.pop() // last element is total obj -> is necessary remove them

    // console.log(object2Array, state.items);

    // console.log(total);
    // console.groupEnd();

    return {
      ...state,
      total: state.total.map((value, key) =>
        key === action.key_data ? totalFinal : value
      ),
      items: {
        ...state.items,
        total: state.total.map((value, key) =>
          key === action.key_data ? totalFinal : value
        )
      }
    }
  }

  // this action is for recalculate all porcentage for this respective date column (by: action.keydata)
  if (action.type === 'ATIVO_PORCENTAGE_ALL_ELEMENTS') {
    const total = state.total[action.key_data]

    const porcentageCalc = value => {
      return total === 0 ? 0 : ((value * 100) / total).toFixed(0)
    }
    const newStateItems = Object.keys(state.items)
    newStateItems.pop() // last key is super total

    newStateItems.forEach((key, item) => {
      state.items[key].items.map(item =>
        item.datas.map((data, key) => {
          if (key === action.key_data) {
            data.percent = porcentageCalc(data.value) + '%'
          } else {
            return data
          }

          return null
        })
      )
      return null
    })

    return {
      ...state,
      items: state.items
    }
  }

  if (action.type === 'ATIVO_UPDATE_PROGRESS') {
    return {
      ...state,
      progress: {
        ...progress,
        [action.keyName]: true
      }
    }
  }

  // SUM ALL SUB TOTAL FROM SPECIFIC ITEMS WITHOUT VALUE
  if (action.type === 'SUM_SUB_TOTAL_WITHOUT_VALUE') {
    const newState = JSON.parse(JSON.stringify(state.items))

    const newStateItems = Object.keys(newState)

    newStateItems.pop() // last key is super total
    const superTotal = Array(action.datas.length).fill(0)

    // foreach for each ITEM
    newStateItems.forEach((key, item) => {
      const items = newState[key].items
      const total = Array(action.datas.length).fill(0) // new total with zeros

      // foreach for each sub ITEM
      items.forEach(item => {
        // foreach for each data
        total.forEach((totalValue, keyTotal) => {
          if (item.datas === undefined) {
            item.datas = Array(action.datas.length)
              .fill()
              .map(u => ({
                value: 0,
                percent: 0
              }))
          }

          // check if datas is defined and array has elements
          if (item.datas !== undefined && item.datas.length > 0) {
            total[keyTotal] +=
              item.datas[keyTotal] && item.datas[keyTotal].value !== undefined
                ? item.datas[keyTotal].value
                : 0
            superTotal[keyTotal] +=
              item.datas[keyTotal] && item.datas[keyTotal].value !== undefined
                ? item.datas[keyTotal].value
                : 0
          }
        })
      })

      items.total = total
    })

    console.groupEnd()

    return {
      ...state
      // items: new_state,
      // total: superTotal
    }
  }

  if (action.type === 'REMOVE_DATE') {
    const dataIndex = action.value.id

    return {
      ...state,
      items: {
        ...state.items,
        ativo_circulante: {
          ...state.items.ativo_circulante,
          items: state.items.ativo_circulante.items.map((itemUnico, index) => ({
            ...itemUnico,
            datas:
              itemUnico.datas &&
              itemUnico.datas.filter((data, dataKey) => dataKey !== dataIndex)
          })),
          total:
            state.items.ativo_circulante.total &&
            state.items.ativo_circulante.total.filter(
              (item, index) => index !== dataIndex
            )
        },
        realizavel_longo_prazo: {
          ...state.items.realizavel_longo_prazo,
          items: state.items.realizavel_longo_prazo.items.map(
            (itemUnico, index) => ({
              ...itemUnico,
              datas:
                itemUnico.datas &&
                itemUnico.datas.filter((data, dataKey) => dataKey !== dataIndex)
            })
          ),
          total:
            state.items.realizavel_longo_prazo.total &&
            state.items.realizavel_longo_prazo.total.filter(
              (item, index) => index !== dataIndex
            )
        },
        investimentos: {
          ...state.items.investimentos,
          items: state.items.investimentos.items.map((itemUnico, index) => ({
            ...itemUnico,
            datas:
              itemUnico.datas &&
              itemUnico.datas.filter((data, dataKey) => dataKey !== dataIndex)
          })),
          total:
            state.items.investimentos.total &&
            state.items.investimentos.total.filter(
              (item, index) => index !== dataIndex
            )
        },
        imobilizado: {
          ...state.items.imobilizado,
          items: state.items.imobilizado.items.map((itemUnico, index) => ({
            ...itemUnico,
            datas:
              itemUnico.datas &&
              itemUnico.datas.filter((data, dataKey) => dataKey !== dataIndex)
          })),
          total:
            state.items.imobilizado.total &&
            state.items.imobilizado.total.filter(
              (item, index) => index !== dataIndex
            )
        },
        ativo_diferido: {
          ...state.items.ativo_diferido,
          items: state.items.ativo_diferido.items.map((itemUnico, index) => ({
            ...itemUnico,
            datas:
              itemUnico.datas &&
              itemUnico.datas.filter((data, dataKey) => dataKey !== dataIndex)
          })),
          total:
            state.items.ativo_diferido.total &&
            state.items.ativo_diferido.total.filter(
              (item, index) => index !== dataIndex
            )
        },
        ativo_permanente: {
          ...state.items.ativo_permanente,
          items: state.items.ativo_permanente.items.map((itemUnico, index) => ({
            ...itemUnico,
            datas:
              itemUnico.datas &&
              itemUnico.datas.filter((data, dataKey) => dataKey !== dataIndex)
          })),
          total:
            state.items.ativo_permanente &&
            state.items.ativo_permanente.total.filter(
              (item, index) => index !== dataIndex
            )
        },
        total:
          state.total &&
          state.total.filter((item, index) => index !== dataIndex)
      },
      total:
        state.total && state.total.filter((item, index) => index !== dataIndex)
    }
  }

  if (action.type === 'ADD_NEW_DATE') {
    const dateBase = { value: 0, percent: 0 }
    const totalBase = 0

    return {
      ...state,
      items: {
        ...state.items,
        ativo_circulante: {
          ...state.ativo_circulante,
          items: state.items.ativo_circulante.items.map((itemUnico, index) => ({
            ...itemUnico,
            datas: itemUnico.datas
              ? [...itemUnico.datas, dateBase]
              : [{ value: 0, percent: 0 }]
          })),
          total: [...state.items.ativo_circulante.total, totalBase]
        },
        realizavel_longo_prazo: {
          ...state.realizavel_longo_prazo,
          items: state.items.realizavel_longo_prazo.items.map(
            (itemUnico, index) => ({
              ...itemUnico,
              datas: itemUnico.datas
                ? [...itemUnico.datas, dateBase]
                : [{ value: 0, percent: 0 }]
            })
          ),
          total: [...state.items.realizavel_longo_prazo.total, totalBase]
        },
        investimentos: {
          ...state.investimentos,
          items: state.items.investimentos.items.map((itemUnico, index) => ({
            ...itemUnico,
            datas: itemUnico.datas
              ? [...itemUnico.datas, dateBase]
              : [{ value: 0, percent: 0 }]
          })),
          total: [...state.items.investimentos.total, totalBase]
        },
        imobilizado: {
          ...state.imobilizado,
          items: state.items.imobilizado.items.map((itemUnico, index) => ({
            ...itemUnico,
            datas: itemUnico.datas
              ? [...itemUnico.datas, dateBase]
              : [{ value: 0, percent: 0 }]
          })),
          total: [...state.items.imobilizado.total, totalBase]
        },
        ativo_diferido: {
          ...state.ativo_diferido,
          items: state.items.ativo_diferido.items.map((itemUnico, index) => ({
            ...itemUnico,
            datas: itemUnico.datas
              ? [...itemUnico.datas, dateBase]
              : [{ value: 0, percent: 0 }]
          })),
          total: [...state.items.ativo_diferido.total, totalBase]
        },
        ativo_permanente: {
          ...state.ativo_permanente,
          items: state.items.ativo_permanente.items.map((itemUnico, index) => ({
            ...itemUnico,
            datas: itemUnico.datas
              ? [...itemUnico.datas, dateBase]
              : [{ value: 0, percent: 0 }]
          })),
          total: [...state.items.ativo_permanente.total, totalBase]
        },
        total: state.total && [...state.total, totalBase]
      },
      total: state.total && [...state.total, totalBase]
    }
  }

  if (action.type === 'UPDATE_DATE') {
    // const totalDatas = action.value
    // const totalAtivo = state.total.length
    // const total_datas = action.value;
    // const model_item = { value: 0, percent: 0 };
    // const newStateItems = Object.entries(state.items);
    // newStateItems.pop();
    // let total_array = new Array(total_datas).fill(0);
    // let total_item = total_array.map((item) => model_item);
    // let new_state = JSON.parse(JSON.stringify(state.items));
    // newStateItems.forEach((key, item) => {
    // 	const items = new_state[key[0]].items;
    // 	let total = total_array;
    // 	items.forEach((item) => {
    // 		item.total = total_array;
    // 		total.forEach((total_value, key_total) => {
    // 			item.datas = total_item;
    // 		});
    // 	});
    // 	new_state[key[0]].total = total_array;
    // 	new_state.total = total_array;
    // });
    // return {
    // 	...state,
    // 	items: new_state,
    // 	total: total_array
    // };
  }

  if (action.type === 'ATIVO_PERMANENTE') {
    const investimentosTotal = parseFloat(
      state.items.investimentos.total[action.key_data]
    )
    const imobilizadoTotal = parseFloat(
      state.items.imobilizado.total[action.key_data]
    )
    const ativoDiferido = parseFloat(
      state.items.ativo_diferido.total[action.key_data]
    )

    const totalPermanente =
      investimentosTotal + imobilizadoTotal + ativoDiferido

    return {
      ...state,
      items: {
        ...state.items,
        ativo_permanente: {
          ...state.items.ativo_permanente,
          total: state.items.ativo_permanente.total.map((total, keyData) =>
            keyData === action.key_data ? totalPermanente : total
          )
        }
      }
    }
  }
  return state
}
