import React from 'react'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import DatePicker, { registerLocale } from 'react-datepicker'

import { FaTrashAlt, FaPercentage } from 'react-icons/fa'

import * as ParceirosActions from '../../redux/store/actions/parceiros'

import ptbr from 'date-fns/locale/pt'

registerLocale('ptbr', ptbr)

const isNullOrUndefined = value => value === null || value === undefined

const ParceiroItem = props => {
  const { indexItem, parceiros, dispatch } = props

  return (
    <div className='col-auto col-value parceiro-item'>
      <div className='field-parceiro'>
        <input
          type='text'
          placeholder='Parceiro 2'
          className='field-parceiro field-parceiro-input'
          value={parceiros[indexItem].parceiro}
          onChange={async e => {
            dispatch(
              ParceirosActions.changeItem(indexItem, 'parceiro', e.target.value)
            )
          }}
        />
        <button
          className='btn btn-outline-primary btn-sm btn-remove'
          onClick={() => {
            Swal.fire({
              title: 'Excluir este Item',
              text: 'Deseja realmente excluir este item ?',
              showCancelButton: true,
              confirmButtonColor: '#535ad0',
              cancelButtonColor: '#262b88',
              icon: 'warning'
            }).then(async result => {
              if (result.value) {
                await dispatch(ParceirosActions.removeItem(indexItem))
              }
            })
          }}
        >
          <FaTrashAlt />
        </button>
      </div>

      <DatePicker
        dateFormat='dd/MM/yyyy'
        locale={ptbr}
        selected={
          parceiros[indexItem].data_cadastro instanceof Date
            ? parceiros[indexItem].data_cadastro
            : isNullOrUndefined(parceiros[indexItem].data_cadastro)
              ? null
              : new Date(parceiros[indexItem].data_cadastro)
        }
        onChange={tempV => {
          dispatch(
            ParceirosActions.changeItem(indexItem, 'data_cadastro', tempV)
          )
        }}
        className='form-control form-control-sm date-item'
        placeholder='selecionar uma data'
      />

      <input
        type='text'
        placeholder='Limite'
        value={parceiros[indexItem].limite}
        onChange={async e => {
          dispatch(
            ParceirosActions.changeItem(indexItem, 'limite', e.target.value)
          )
        }}
      />

      <DatePicker
        dateFormat='dd/MM/yyyy'
        locale={ptbr}
        selected={
          parceiros[indexItem].ultima_operacao instanceof Date
            ? parceiros[indexItem].ultima_operacao
            : isNullOrUndefined(parceiros[indexItem].ultima_operacao)
              ? null
              : new Date(parceiros[indexItem].ultima_operacao)
        }
        onChange={tempV => {
          dispatch(
            ParceirosActions.changeItem(indexItem, 'ultima_operacao', tempV)
          )
        }}
        className='form-control form-control-sm date-item'
        placeholder='selecionar uma data'
      />

      <input
        type='text'
        placeholder='Risco / VCTO'
        value={parceiros[indexItem].risco_vcto}
        onChange={async e => {
          dispatch(
            ParceirosActions.changeItem(indexItem, 'risco_vcto', e.target.value)
          )
        }}
      />
      <input
        type='text'
        placeholder='Vencidos'
        value={parceiros[indexItem].vencidos}
        onChange={async e => {
          dispatch(
            ParceirosActions.changeItem(indexItem, 'vencidos', e.target.value)
          )
        }}
      />
      <input
        type='text'
        placeholder='VOP'
        value={parceiros[indexItem].vop}
        onChange={async e => {
          dispatch(
            ParceirosActions.changeItem(indexItem, 'vop', e.target.value)
          )
        }}
      />
      <div className='input-group'>
        <input
          placeholder='Liquidez Pontual'
          value={parceiros[indexItem].liquidez}
          type='number'
          step='0.1'
          onChange={async e => {
            dispatch(
              ParceirosActions.changeItem(indexItem, 'liquidez', e.target.value)
            )
          }}
          className='input_porcentage'
        />
        <div className='input-group-append'>
          <span className='input-group-text porcentage-text' id='basic-addon2'>
            <FaPercentage style={{ color: 'black', width: '28px' }} />
          </span>
        </div>
      </div>
      <div className='input-group'>
        <input
          placeholder='Atrasos'
          value={parceiros[indexItem].atrasos}
          type='number'
          step='0.1'
          onChange={async e => {
            dispatch(
              ParceirosActions.changeItem(indexItem, 'atrasos', e.target.value)
            )
          }}
          className='input_porcentage'
        />
        <div className='input-group-append'>
          <span className='input-group-text porcentage-text' id='basic-addon2'>
            <FaPercentage style={{ color: 'black', width: '28px' }} />
          </span>
        </div>
      </div>
      <div className='input-group'>
        <input
          placeholder='Recompra'
          value={parceiros[indexItem].recompra}
          type='number'
          step='0.1'
          onChange={async e => {
            dispatch(
              ParceirosActions.changeItem(indexItem, 'recompra', e.target.value)
            )
          }}
          className='input_porcentage'
        />
        <div className='input-group-append'>
          <span className='input-group-text porcentage-text' id='basic-addon2'>
            <FaPercentage style={{ color: 'black', width: '28px' }} />
          </span>
        </div>
      </div>
      <div className='input-group'>
        <input
          placeholder='Cartório'
          value={parceiros[indexItem].cartorio}
          type='number'
          step='0.1'
          onChange={async e => {
            dispatch(
              ParceirosActions.changeItem(indexItem, 'cartorio', e.target.value)
            )
          }}
          className='input_porcentage'
        />
        <div className='input-group-append'>
          <span className='input-group-text porcentage-text' id='basic-addon2'>
            <FaPercentage style={{ color: 'black', width: '28px' }} />
          </span>
        </div>
      </div>

      <textarea
        type='text'
        placeholder='Observações'
        className='input-textarea'
        value={parceiros[indexItem].obs}
        onChange={async e => {
          dispatch(
            ParceirosActions.changeItem(indexItem, 'obs', e.target.value)
          )
        }}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  parceiros: state.parceiros
})

export default connect(mapStateToProps)(ParceiroItem)
