import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useSWR from 'swr'

import { setUpstreamData } from '../redux/store/concorrencia'

import { fetchDataAndParse } from './updateREDUX'

export default function Concorrencia () {
  const dispatch = useDispatch()
  const isGroup = useSelector(state => state.sidebar.isGroup)
  const location = useWindowLocation()

  const { cnpj, dateVersion } = useMemo(() => {
    if (!location) {
      return {
        cnpj: null,
        dateVersion: null
      }
    }

    const cnpj = location.pathname.split('/')[2]
    const urlParams = new URLSearchParams(location.search)
    const dateVersion = urlParams.get('dateVersion')

    return {
      cnpj,
      dateVersion
    }
  }, [location])

  const {
    data: upstreamData,
    error,
    isLoading
  } = useSWR(
    cnpj ? ['/get_spread', cnpj, dateVersion, isGroup] : null,
    async ([_, cnpj, dateVersion, isGroup]) =>
      fetchDataAndParse({
        cnpj,
        isGroup,
        dateVersion
      })
  )

  useEffect(() => {
    if (isLoading || error) {
      return
    }

    const newUpstreamData = JSON.parse(
      JSON.stringify({
        ...upstreamData
      })
    )

    dispatch(setUpstreamData(newUpstreamData))
  }, [upstreamData, error, isLoading, isGroup])

  return <div />
}

const useIsMounted = () => {
  const [isMounted, setIsMounted] = useState(false)
  useEffect(() => {
    setIsMounted(() => true)
  }, [])

  return isMounted
}

const useWindowLocation = () => {
  const isMounted = useIsMounted()
  const [location, setLocation] = useState(
    isMounted ? window.location : undefined
  )

  useEffect(() => {
    if (!isMounted) return

    const setWindowLocation = () => {
      setLocation(window.location)
    }

    if (!location) {
      setWindowLocation()
    }

    window.addEventListener('popstate', setWindowLocation)

    return () => {
      window.removeEventListener('popstate', setWindowLocation)
    }
  }, [isMounted, location])

  return location
}
