import { toast } from 'react-toastify'
import diff from 'deep-diff'
import { put, select, call } from 'redux-saga/effects'
import get from 'lodash.get'
import Swal from 'sweetalert2'

import { parseReduxData } from '../../lib/parse-redux'

import { factoryDate, formatWithTimezone, hashObject } from '../../utils'

import * as DadosActions from '../store/actions/dados_datas'
import * as AtivoActions from '../store/actions/ativo'
import * as PassivoActions from '../store/actions/passivo'
import * as MutacaoActions from '../store/actions/mutacao'
import * as DREActions from '../store/actions/dre'
import * as FundosActions from '../store/actions/fundos'
import * as ParceirosActions from '../store/actions/parceiros'
import * as HighlightsActions from '../store/actions/highlights'
import { updateFromReduxStyle as ratingUpdateFromReduxStyle } from '../store/reducers/rating'
import { setOriginalData } from '../store/concorrencia'

export function * computeDiffOriginal (action) {}

export function * computeDiffUpstream (action) {
  console.debug('computeDiffUpstream')

  try {
    const parsedUpstreamData = parseReduxData(action.payload)
    const upstreamData = {
      original: action.payload,
      data: parsedUpstreamData,
      hash: hashObject(parsedUpstreamData)
    }

    const originalDataState = yield select(
      state => state.concorrencia.originalData
    )
    const originalData = {
      data: parseReduxData(originalDataState.data),
      hash: originalDataState.hash
    }

    if (!originalData.hash) {
      return
    }

    const hasSameHash = originalData.hash === upstreamData.hash
    if (hasSameHash) {
      return
    }

    const diffOriginalAndUpstream = diffObjects(
      originalData.data,
      upstreamData.data
    )
    const hasDiff = diffOriginalAndUpstream?.length > 0

    if (!hasDiff) {
      return
    }

    console.debug('tem diferença upstream', diffOriginalAndUpstream)

    const currentData = generateCurrentData(yield select(state => state))
    if (currentData.hash === originalData.hash) {
      console.debug(
        'nenhum dado foi editado localmente, portanto os dados serão atualizados com os valores do upstream'
      )

      const copyUpstreamOriginalData = JSON.parse(
        JSON.stringify(upstreamData.original)
      )

      // atualiza valores atuais
      yield call(updateCurrentDataFromBootstrap, upstreamData.original)

      // atualiza os valores originais para que haja uma nova referência de comparação e não gere falso positivo de atualização local
      yield put(setOriginalData(copyUpstreamOriginalData))

      return
    }

    const diffOriginalAndCurrent = diffObjects(
      originalData.data,
      currentData.data
    )

    const { safetyChanges, unsafeChanges } =
      getChangesFromUpstreamWithoutConflict({
        diffUpstream: diffOriginalAndUpstream,
        diffCurrent: diffOriginalAndCurrent,
        currentData: currentData.data
      })

    let nextCurrentData = currentData.data
    let unsafeChangesApplied = false

    if (unsafeChanges.length > 0) {
      console.debug('tem conflito', unsafeChanges)
      const tabsWithConflict = Array.from(
        new Set(unsafeChanges.map(item => item.path.split('.')[0]))
      )

      const dictTab = {
        dados: 'Dados',
        ativos: 'Ativos',
        passivo: 'Passivo',
        dre: 'DRE',
        mutacao: 'Mutação',
        fundos: 'Fundos',
        parceiros: 'Parceiros',
        rating: 'Rating'
      }

      const tabsWithConflictNames = tabsWithConflict.map(item => ({
        label: dictTab[item],
        value: item
      }))

      const { isConfirmed, value: mergeStrategy } = yield call(
        showPopupConflict,
        tabsWithConflictNames
      )

      if (!isConfirmed) {
        return
      }

      const changesToApply = unsafeChanges
        .map(({ upstream, current, path }) => {
          const root = path.split('.')[0]

          const isCurrent = mergeStrategy[root] === 'current'
          const isUpstream = mergeStrategy[root] === 'upstream'

          if (isCurrent) {
            return current
          }

          if (isUpstream) {
            return upstream
          }

          return null
        })
        .filter(Boolean)

      console.debug('changesToApply', changesToApply)

      nextCurrentData = applyChanges(nextCurrentData, changesToApply)
      unsafeChangesApplied = true
      // return
    }

    if (safetyChanges.length > 0) {
      console.debug('atualizações não conflitantes:', safetyChanges)
      nextCurrentData = applyChanges(nextCurrentData, safetyChanges)
      console.debug('próximo current após aplicar mudanças', nextCurrentData)

      yield call(updateCurrentDataFromReduxStyle, nextCurrentData)
      yield put(setOriginalData(upstreamData.original))
    } else if (unsafeChangesApplied) {
      yield call(updateCurrentDataFromReduxStyle, nextCurrentData)
      yield put(setOriginalData(upstreamData.original))
    }

    console.debug(
      'tem diferença original e atual local:',
      diffOriginalAndCurrent,
      'original:',
      originalData.data,
      'current:',
      currentData.data,
      'hashes',
      originalData.hash,
      currentData.hash
    )
  } catch (err) {
    console.error(err)
    toast.error('Erro detectar atualização', {
      toastId: 'error-detectar-atualizacao-upstream',
      position: toast.POSITION.BOTTOM_RIGHT
    })
  }
}

const diffObjects = (obj1, obj2) => {
  const copy1 = JSON.parse(JSON.stringify(obj1))
  const copy2 = JSON.parse(JSON.stringify(obj2))
  if (copy1?.dados?.datas) {
    copy1.dados.datas = copy1.dados.datas.map(item => ({
      ...item,
      data: formatWithTimezone(factoryDate(item.data), 'yyyy-MM-dd')
    }))
  }

  if (copy2?.dados?.datas) {
    copy2.dados.datas = copy2.dados.datas.map(item => ({
      ...item,
      data: formatWithTimezone(factoryDate(item.data), 'yyyy-MM-dd')
    }))
  }

  // ignora alguns campos na comparação
  const filter = (path, key) => {
    const filterDados = () => {
      if (path.length !== 1) {
        return false
      }

      if (JSON.stringify(path) !== JSON.stringify(['dados'])) {
        return false
      }

      return ['show_cidade'].includes(key)
    }

    const filterAtivos = () => {
      const joinPath = path.join('.')
      if (path.length === 2 && joinPath === 'ativos.total') {
        return true
      }

      if (
        path.length === 4 &&
        joinPath.startsWith('ativos.') &&
        joinPath.endsWith('total')
      ) {
        return true
      }

      return (
        joinPath.startsWith('ativos.') && ['percent', 'total'].includes(key)
      )
    }

    const filterPassivos = () => {
      const joinPath = path.join('.')
      if (path.length === 2 && joinPath === 'passivo.total') {
        return true
      }

      return (
        joinPath.startsWith('passivo.') && ['percent', 'total'].includes(key)
      )
    }

    const filterMutacao = () => {
      const joinPath = path.join('.')
      if (path.length === 2 && joinPath === 'mutacao.total') {
        return true
      }

      return (
        joinPath.startsWith('mutacao.') && ['percent', 'total'].includes(key)
      )
    }

    const filterFundos = () => {
      if (path.length !== 1) {
        return false
      }

      if (JSON.stringify(path) !== JSON.stringify(['fundos'])) {
        return false
      }

      return ['total', 'total_fundos', 'total_bancos'].includes(key)
    }

    const filterDre = () => {
      const joinPath = path.join('.')
      if (
        (path.length === 4 || path.length === 2) &&
        joinPath.startsWith('dre.') &&
        joinPath.endsWith('total')
      ) {
        return true
      }

      return joinPath.startsWith('dre.') && ['percent', 'total'].includes(key)
    }

    return (
      filterDados() ||
      filterFundos() ||
      filterAtivos() ||
      filterPassivos() ||
      filterMutacao() ||
      filterDre()
    )
  }

  const diffObj = diff.diff(copy1, copy2, filter)

  return diffObj
}

const generateCurrentData = state => {
  const {
    dados,
    ativo,
    passivo,
    mutacao,
    fundos,
    dre,
    parceiros,
    rating,
    highlights
  } = state

  const data = JSON.parse(
    JSON.stringify({
      // datas,
      dados,
      ativos: ativo,
      passivo,
      dre,
      mutacao,
      fundos,
      parceiros,
      rating,
      highlights
    })
  )

  if (data?.dados?.progress) {
    delete data.dados.progress
  }

  if (data?.ativos?.progress) {
    delete data.ativos.progress
  }

  if (data?.passivo?.progress) {
    delete data.passivo.progress
  }

  if (data?.mutacao?.progress) {
    delete data.mutacao.progress
  }

  if (data?.dre?.progress) {
    delete data.dre.progress
  }

  const hash = hashObject(data)

  return {
    hash,
    data
  }
}

const getChangesFromUpstreamWithoutConflict = ({
  diffUpstream,
  diffCurrent,
  currentData
}) => {
  diffUpstream = diffUpstream || []
  diffCurrent = diffCurrent || []

  const safetyChanges = []
  const unsafeChanges = []

  diffUpstream.forEach(item => {
    const path = item.path.join('.')
    const isConflict = diffCurrent.find(item => item.path.join('.') === path)
    const isNewItemInArray = item.kind === 'A' && item.item.kind === 'N'
    if (!isConflict) {
      safetyChanges.push(item)
    } else if (isConflict && isNewItemInArray) {
      const array = get(currentData, path)
      const lastIndex = array.length
      const copyItem = { ...item }
      copyItem.index = lastIndex
      safetyChanges.push(copyItem)
    } else {
      unsafeChanges.push({ upstream: item, current: isConflict, path })
    }
  })

  if (unsafeChanges.length > 0) {
    console.debug('unsafeChanges', unsafeChanges)
  }

  return { safetyChanges, unsafeChanges }
}

function * updateCurrentDataFromBootstrap (nextData) {
  const totalDatas = nextData.dados.datas.length
  yield put(DadosActions.bootstrap(nextData.dados))
  yield put(AtivoActions.bootstrap(nextData.ativos, totalDatas))
  yield put(PassivoActions.bootstrap(nextData.passivos, totalDatas))
  yield put(MutacaoActions.bootstrap(nextData.mutacao, totalDatas))
  yield put(DREActions.bootstrap(nextData.dre, totalDatas))
  yield put(FundosActions.bootstrap(nextData.fundos))
  yield put(ParceirosActions.bootstrap(nextData.parceiros))
  if (nextData.highlights) {
    yield put(HighlightsActions.bootstrap(nextData.highlights))
  }
}

function * updateCurrentDataFromReduxStyle (nextData) {
  const totalDatas = nextData.dados.datas.length

  yield put(DadosActions.updateFromReduxStyle(nextData.dados))
  yield put(AtivoActions.updateFromReduxStyle(nextData.ativos, totalDatas))
  yield put(DREActions.updateFromReduxStyle(nextData.dre, totalDatas))
  yield put(PassivoActions.updateFromReduxStyle(nextData.passivo, totalDatas))
  yield put(MutacaoActions.updateFromReduxStyle(nextData.mutacao, totalDatas))
  yield put(FundosActions.updateFromReduxStyle(nextData.fundos))
  yield put(ParceirosActions.updateFromReduxStyle(nextData.parceiros))
  yield put(ratingUpdateFromReduxStyle(nextData.rating))

  if (nextData.highlights) {
    yield put(HighlightsActions.updateFromReduxStyle(nextData.highlights))
  }
}

function applyChanges (target, changes) {
  return changes.reduce((acc, change) => {
    diff.applyChange(acc, true, change)
    return acc
  }, target)
}

async function showPopupConflict (tabsWithConflictNames) {
  const response = await Swal.fire({
    title: 'Conflito detectado',
    html: `
    <div class='lead'>
      Foram encontrados conflitos entre a sua versão local e a versão do servidor. Selecione como deseja resolver o conflito:<br>
      <br>
    </div>
    ${tabsWithConflictNames
      .map(
        ({ value, label }) => `
      <div class='form-group row my-0'>
        <label for='conflitos-${value}' class='col-sm-4 col-form-label'>${label}</label>
        <div class='col-sm-8'>
          <select name='${value}' id='conflitos-${value}' class='form-control'>
            <option value='' selected disabled hidden>Selecione uma opção</option>
            <option value='current'>minha versão</option>
            <option value='upstream'>versão do servidor</option>
          </select>
        </div>
      </div>
    `
      )
      .join('<br>')}
    `,
    icon: 'warning',
    confirmButtonText: 'Prosseguir',
    allowOutsideClick: false,
    allowEscapeKey: false,
    preConfirm: () => {
      const mappedValues = tabsWithConflictNames.map(({ value, label }) => ({
        key: value,
        label,
        value: document.getElementById(`conflitos-${value}`)?.value
      }))

      const emptyFields = mappedValues.filter(({ value }) => !value)

      if (emptyFields.length === mappedValues.length) {
        Swal.showValidationMessage('Preencha todos os campos')
        return
      }

      if (emptyFields.length > 0) {
        Swal.showValidationMessage(
          `Preencha os campos: ${emptyFields
            .map(({ label }) => label.toLowerCase())
            .join(', ')}`
        )
        return
      }

      Swal.resetValidationMessage()

      const mappedValuesDict = mappedValues.reduce((acc, item) => {
        acc[item.key] = item.value
        return acc
      }, {})

      return new Promise(resolve => resolve(mappedValuesDict))
    }
  })

  return response
}
