import React from 'react'
import { connect } from 'react-redux'

import * as FundosActions from '../../redux/store/actions/fundos'
import ChartFundo from './../../pages/fundos/chart-fundos'

import './../item-currency/item-currency.css'

class TotalFundos extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      dados: this.props.dados.datas,
      total: this.props.fundos.total
    }

    this.props.fundos.total =
      this.props.fundos.total === undefined ||
      this.props.fundos.total.length === 0
        ? new Array(this.props.dados.datas.length).fill(0)
        : this.props.fundos.total

    this.sumTotal()
  }

  async sumTotal () {
    await this.props.dispatch(FundosActions.sumTotal())
  }

  render () {
    const { fundos } = this.props

    return (
      <div className='ativoTotal'>
        <div className='row justify-content-center'>
          <div className='col-11'>
            <div className='row justify-content-between'>
              <div className='col-9'>
                <div className='row justify-content-start'>
                  <div className='col-4 row justify-content-start '>
                    <div className='total-txt col-auto text-right'>Total</div>
                    <div className='total-secondLine-fundos'>
                      <span>R$</span>
                      <span>{fundos.total.toLocaleString('pt-BR')}</span>
                    </div>
                  </div>
                  <div className='col-4 row justify-content-start'>
                    <div className='total-txt total-txt-sub col-auto text-right'>
                      Fundos
                    </div>
                    <div className='total-secondLine-fundos'>
                      <span>R$</span>
                      <span>{fundos.total_fundos.toLocaleString('pt-BR')}</span>
                    </div>
                  </div>

                  <div className='col-4 row justify-content-start'>
                    <div className='total-txt total-txt-sub col-auto text-right'>
                      Bancos
                    </div>
                    <div className='total-secondLine-fundos'>
                      <span>R$</span>
                      <span>{fundos.total_bancos.toLocaleString('pt-BR')}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-3'>
                <ChartFundo />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  dados: state.dados,
  fundos: state.fundos
})

export default connect(mapStateToProps)(TotalFundos)
