import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

import { MdTrendingDown } from 'react-icons/md'

import { saveAllData } from '../../lib/save-data'

import Sidebar from './../../components/sidebar'
import PageTitle from './../../components/pageTitle'
import TotalMutacao from '../../components/total/mutacao'
import Footer from './../../components/footer'
import ItemCurrency from './../../components/item-currency'
import ChartToogle from './../../components/chart-toogle'
import SubTotalToogle from './../../components/subtotal'

import * as SidebarActions from '../../redux/store/actions/sidebar'
import * as MutacaoActions from '../../redux/store/actions/mutacao'

import 'react-toastify/dist/ReactToastify.css'

class Passivo extends Component {
  constructor (props) {
    super(props)
    this.props.clickSidebar(3)
    this.sumTotal()
  }

  async clickSave () {
    Swal.fire({
      title: 'Salvar Informações',
      text: 'Deseja salvar as informações inseridas ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#525ad0',
      cancelButtonColor: '#272c84'
    }).then(async result => {
      if (!result.dismiss) {
        await saveAllData(this.props).then(res => {
          toast('Informações salvas no servidor', {
            className: 'toast-background',
            bodyClassName: 'toast-body',
            progressClassName: 'toast-progress',
            position: 'top-right',
            hideProgressBar: true,
            style: {
              borderRadius: '10px'
            }
          })
        })
      }
    })
  }

  async sumTotal () {
    await this.props.sumSubTotal(this.props.dados.datas)
  }

  render () {
    const { mutacao } = this.props

    return (
      <div className='container-fluid min-vh-100' style={{ padding: '0' }}>
        <div className='row min-vh-100 no-gutters'>
          <Sidebar />
          <div className='content col-auto'>
            <PageTitle
              title='Demonstrativo de Mutação do PL'
              icon={MdTrendingDown}
              btnSalvar={this.clickSave.bind(this)}
              redux_data={mutacao}
            />
            <div className='bodyContent' style={{ paddingTop: '3em' }}>
              <ItemCurrency
                item={mutacao.items.patrimonio_liquido_final}
                keyName='patrimonio_liquido_final'
                name='Patrimônio Líquido Final'
                type='mutacao'
                redux_data={mutacao}
                hide_chart={!this.props.sidebar.showChart}
              />

              <ItemCurrency
                item={mutacao.items.dif_patrimonio_liquido}
                keyName='dif_patrimonio_liquido'
                name='Diferença do Patrimônio Líquido'
                type='mutacao'
                redux_data={mutacao}
                hide_chart
                redux_command='diferenca_patrimonio_liquido'
              />
            </div>
            <ChartToogle />
            <SubTotalToogle />
            <TotalMutacao />

            <Footer />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  dados: state.dados,
  ativo: state.ativo,
  passivo: state.passivo,
  dre: state.dre,
  mutacao: state.mutacao,
  fundos: state.fundos,
  parceiros: state.parceiros,
  rating: state.rating,

  sidebar: state.sidebar,
  isGroup: state.sidebar.isGroup
})

const mapDispatchToProps = dispatch => ({
  dispatch,
  clickSidebar: item => dispatch(SidebarActions.clickSidebar(item)),
  sumSubTotal: dados => dispatch(MutacaoActions.sumSubTotal(dados))
})

export default connect(mapStateToProps, mapDispatchToProps)(Passivo)
