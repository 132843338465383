import { Auth } from 'aws-amplify'
import { toast } from 'react-toastify'
import camelcaseKeys from 'camelcase-keys'
import { zonedTimeToUtc, format } from 'date-fns-tz'
import { ptBR } from 'date-fns/locale'
import shajs from 'sha.js'
import { isValidCPF, isValidCNPJ } from '@brazilian-utils/brazilian-utils'

const { version } = require('../package.json')

export const VERSION = version

export const hashObject = obj => {
  return shajs('sha256').update(JSON.stringify(obj)).digest('hex')
}

export const logout = () => {
  return Auth.signOut().catch(err => {
    console.error(err)
    toast.error('Erro ao sair', {
      position: toast.POSITION.BOTTOM_RIGHT,
      toastId: 'logout-error'
    })
  })
}

export const getIdToken = async () => {
  const currentUser = await Auth.currentAuthenticatedUser()

  return currentUser.signInUserSession.idToken.jwtToken
}

export const toCamelCase = value => {
  if (typeof value === 'string') {
    return Object.keys(camelcaseKeys({ [value]: '' }))[0]
  }

  return camelcaseKeys(value, { deep: true })
}

export const factoryDate = (date, timezone = 'America/Sao_Paulo') => {
  if (!timezone) {
    return new Date(date)
  }

  return zonedTimeToUtc(date, timezone)
}

export const formatWithTimezone = (
  date,
  pattern,
  timezone = 'America/Sao_Paulo'
) => {
  return format(date, pattern, { timeZone: timezone, locale: ptBR })
}

/**
 *
 * @param {string} document
 * @returns {boolean}
 */
export const isValidDocument = document => {
  const cleanDocument = document.replace(/\D/g, '')
  return isValidCNPJ(cleanDocument) || isValidCPF(cleanDocument)
}

/**
 *
 * @param {string} document
 * @returns {boolean}
 */
export const isValidCnpj = document => {
  const cleanDocument = document.replace(/\D/g, '')
  return isValidCNPJ(cleanDocument)
}

/**
 *
 * @param {string} document
 * @returns {string}
 */
export const dynamicMask = document => {
  const cleanDocument = document.replace(/\D/g, '')

  if (cleanDocument.length === 11 && isValidCPF(cleanDocument)) {
    return '999.999.999-99'
  }

  return '99.999.999/9999-99'
}
